import { Inject, Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';

import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { LOCAL_STORAGE } from '@safesante/utils';




@Injectable()
export class AppHttpInterceptor implements HttpInterceptor {
    constructor(private router: Router, @Inject(LOCAL_STORAGE) private localStorage: Storage) {

    }
    headers = new Headers({
        'Content-Type': 'application/json',
        'Token': this.localStorage.getItem('Token')
    });
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      // Clone the request to add the new header.
      // const authReq = req.clone({ headers: req.headers.set("Token", localStorage.getItem("Token"))});

      // console.log("Sending request with new header now ...");

      // send the newly created request
      const url =  req.url;
      const apiReq = req.clone({url});

      return next.handle(apiReq)
        .pipe(
          map((response: HttpResponse<any>) => {


            return response;
          }),
          catchError(err => {
            if (err instanceof HttpErrorResponse && !(err.url == null)) {
              if (err.status === 401) {
                this.router.navigate(['/login']);
              }
            }

            return throwError(err);
          })
        );
    }


}
