import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FilePreviewComponent } from '../file-preview/file-preview.component';
import {
  IDocumentModalData,
} from '../base-file-preview.component';

@Component({
  selector: 'safesante-file-preview-dialog-decorator',
  templateUrl: './file-preview-dialog-decorator.component.html',
  styleUrls: ['./file-preview-dialog-decorator.component.css'],
})
export class FilePreviewDialogDecoratorComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IDocumentModalData,
    private dialogRef: MatDialogRef<FilePreviewComponent>
  ) {}

  close() {
    this.dialogRef.close();
  }
}
