<div class="row">
  <div class="col">
    <div class="float-right p-2 pointer">
      <span class="close pointer" data-dismiss="modal" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true"><i class="fas fa-times-circle pointer"></i></span>
      </span>
    </div>
  </div>
</div>
<div *ngIf="!showQrCode" class="row m-3">
  <div class="col-sm-6">
    <div class="mb-1 sc">
      <label class="form-check-label bold" for="phone">Point d'accès WIFI</label>
      <input type="text" class="form-control font18" name="ssid" placeholder="Point d'accès"
        [(ngModel)]="wifiAuthInfoModel.ssid" [ngClass]="{'form-control font18': true, 'invalid-input': !ssidValid()}">
    </div>
  </div>
  <div class="col-sm-6">
    <div class="mb-1 sc">
      <label class="form-check-label bold" for="phone">Mot de passe WIFI</label>
      <input type="password" class="form-control font18" name="ssid-password" placeholder="Mot de pase"
        [(ngModel)]="wifiAuthInfoModel.ssidPassword" [ngClass]="{'form-control font18': true, 'invalid-input': !ssidPasswordValid()}">
    </div>
  </div>
</div>
<div *ngIf="showQrCode" class="p-4">
  <h3>
    Veuillez scanner le code suivant à l'aide de la box santé:
  </h3>
</div>
<div *ngIf="showQrCode" class="qrcode p-4 text-center">
  <!--ngx-qrcode [qrc-value]="value" qrc-errorCorrectionLevel = "L" qrc-class="qr-class"-->
  <qrcode [qrdata]="value" [level]="'M'"></qrcode>
  <!--/ngx-qrcode-->
</div>
<div *ngIf="!showQrCode" class="row m-3" style="text-align:center">
  <button *ngIf="devicePairingStatus!='PAIRED'" type="submit border-r-5"
    class="mx-auto color-white mx-auto shadow purplebackground cp font16 bold text-uppercase border-radius5"
    (click)="generateCode()">générer le code</button>
</div>
<div class="text-center mt-2 mb-2">
  <button class="text-uppercase color-light-blue text-center background-transparent border-0 font13"
    (click)="closeModal()">Annuler</button>
</div>