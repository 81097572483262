import { HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseFilePreviewService } from './base-file-preview.service';
import { FilePreviewDialogDecoratorComponent } from './file-preview-dialog-decorator/file-preview-dialog-decorator.component';

@Injectable({
  providedIn: 'root',
})
export class FilePreviewService extends BaseFilePreviewService {

  displayDocument(
    request: Observable<HttpEvent<never>>,
    documentType: string,
    documentId: number
  ) {

    this.displayDialog(FilePreviewDialogDecoratorComponent,request,documentType, documentId );
  }
}
