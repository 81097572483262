<div class="align-items-start h-100 white-background">
  <div
    class="p-2 col-12 messages-box"
    [ngStyle]="messagesStyle"
    #scrollMe
  >
    <div *ngFor="let message of messages" class="mb-2">
      <div class="row">
        <div class="col-12">
          <div
            *ngIf="!message.pathAttachment"
            [ngClass]="{
              'grey-dialog float-left': message.idPractitioner,
              'blue-dialog float-right': message.idPatient
            }"
          >
            {{ message.message }}
          </div>
          <div
            *ngIf="message.pathAttachment"
            [ngClass]="{
              'grey-dialog float-left': message.idPractitioner,
              'grey-dialog float-right': message.idPatient
            }"
          >
            <div class="float-left">
              <h6 class="regular espac">
                <i class="glyphicon glyphicon-eye-open"></i>
                {{ message.message }}
              </h6>
            </div>
            <a id="dldbtn" href="" #downloadElement hidden>Download </a>
            <div class="float-right">
              <mat-icon
                matTooltip="Consulter"
                class="pointer font-blue ml-3 mr-1"
                (click)="getMessageFile(message)"
                >search</mat-icon
              >
              <mat-icon
                matTooltip="Télécharger"
                class="pointer font-blue ml-1 mr-1"
                (click)="downloadFile(message)"
                >save_alt</mat-icon
              >
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <span
            class="font11 txt-grey"
            [ngClass]="{
              'float-right mr-2': message.idPatient,
              'float-left ml-2': message.idPractitioner
            }"
            >{{ message.date | date: 'HH:mm' }}</span
          >
        </div>
      </div>
    </div>

    <div
      class="d-flex flex-row-reverse"
      *ngIf="sendingFile"
      style="width: 100%"
    >
      <div *ngIf="sendingFile" class="mb-1 col-xs-12 col-sm-2">
        <mat-icon
          type="cancel"
          class="cad pointer font-blue mb-n1"
          (click)="cancelFileUpload()"
          >cancel</mat-icon
        >
      </div>
      <div *ngIf="sendingFile" class="mb-1 col-xs-12 col-sm-10">
        <p class="font-italic font-size: 5rem font mb-n1">
          Transmission du document<b
            >&nbsp;&nbsp;{{ fileUploadingName }}&nbsp;&nbsp;</b
          >en cours...
        </p>
        <ngb-progressbar
          [showValue]="true"
          type="info"
          [value]="uploadProgress"
        ></ngb-progressbar>
      </div>
    </div>
  </div>
  <div class="col-12" [ngStyle]="sendMessagesStyle">
    <form class="w-100 h-100">
      <div class="row h-100">
        <div class="p-2 col-12 border-top h-75">
          <textarea
            #messagInputEl
            type="text"
            inputmode="text"
            (keyup.enter)="sendMessage()"
            rows="4"
            [(ngModel)]="message"
            autocomplete="off"
            class="form-control font16 background-transparent border-0"
            placeholder="Je saisis mon message ici"
            name="message"
          ></textarea>

          <!--input #messagInputEl type="text" inputmode="text" class="form-control font16 background-transparent border-0" placeholder="Je saisis mon message ici" name="message" [(ngModel)]="message" autocomplete="off"-->
          <input
            #inputElement
            type="file"
            hidden
            ng2FileSelect
            accept=".png,.jpeg,.jpg,.pdf"
            [uploader]="uploader"
            (change)="onInputChange()"
            multiple
          />
        </div>
        <div class="p-2 col-12 h-25">
          <button
            mat-flat-button
            color="primary"
            type="submit"
            class="font12 text-uppercase float-right mr-2 ml-1"
            (click)="sendMessage()"
          >
            envoyer
          </button>
          <button
            mat-flat-button
            type="button"
            color="primary"
            class="font12 text-uppercase float-right mr-1 ml-1"
            [disabled]="sendingFile"
            (click)="onSelectFiles()"
          >
            <mat-icon color="blue">attach_file</mat-icon>
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
