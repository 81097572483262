import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'safesante-matlc-reset-user-password-confirmation',
  templateUrl: './reset-user-password-confirmation.component.html',
  styleUrls: ['./reset-user-password-confirmation.component.scss'],
})
export class ResetUserPasswordConfirmationComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
