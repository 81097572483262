import {CaresheetAct} from './caresheetAct';
import { PractitionerModel } from './safesante-ysera';

export interface ICaresheet {
  id?: number;
  accessCaseSelect?: string;
  accident3rdParty?: boolean;
  accident3rdPartyDate?: string;
  actType?: string;
  acts?: {
    caresheetAct0?: CaresheetAct,
    caresheetAct1?: CaresheetAct,
    caresheetAct2?: CaresheetAct,
    caresheetAct3?: CaresheetAct
  };
  actsTotalPayment?: string;
  assuredAddress?: string;
  assuredName?: string;
  assuredRegistrationName?: string;
  atmpDate?: string;
  atmpNumber?: string;
  birthdate?: string;
  cannotSign?: boolean;
  care?: boolean;
  caseSelect?: string;
  healthNetworkCoordinationId?: string;
  structureIdentification?: string;
  date?: string;
  doctorIdentification?: string;
  substituteDoctorName?: string;
  substituteDoctorIdentification?: string;
  treatingDoctor?: string;
  hasNotPaidComplimentary?: boolean;
  hasNotPaidObligatory?: boolean;
  isNewDoctor?: boolean;
  leaveType?: string;
  maternityDate?: string;
  number?: string;
  organizationCode?: string;
  priorDate?: string;
  receiverName?: string;
  receiverRegistrationNumber?: string;
  isUploaded?: boolean;
  path?: string;

}

export class Caresheet implements ICaresheet {

  id: number;
  practitioner: PractitionerModel;

  actType: string;
  isUploaded: boolean;
  path: string;
  idConsultation: number;
  
  constructor(caresheet?: ICaresheet) {
    if(!caresheet) return;
    Object.keys(caresheet).forEach(key => {
      if (key !== 'actType' || (key === 'actType' && caresheet.actType !== 'none')) {
        this[key] = caresheet[key];
      }
    });
  }


  deserialize(caresheet: any): Caresheet {
    Object.assign(this, new Caresheet(caresheet));
    return this;
  }
}
