<div class="p-4">
    <div class="d-flex flex-column justify-content-center text-align-center">
      <div><h1 class="bold">Bienvenue, vous êtes dorénavent inscrit</h1></div>
      <div class="mt-3"><h2 class="light">Pour commencer à utiliser MaTeleconsult, merci de vous identifier en indiquant votre email et votre mot de passe</h2></div>
    </div>
    <div class="d-flex flex-row justify-content-center mt-4">
      <i class="fa fa-check-circle" style="color: #15dbcd; font-size:65pt;"></i>
    </div>
  </div>
  
  
  