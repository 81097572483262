import { PocService } from './../poc.service';
import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PairingDetails, WifiAuthInfoModel } from '../../model/safesante-ysera';

@Component({
  selector: 'safesante-matlc-pairing-info',
  templateUrl: './pairing-info.component.html',
  styleUrls: ['./pairing-info.component.scss'],
})
export class PairingInfoComponent implements OnInit {
  @Input() pairingDetails: PairingDetails;
  lodash;

  //ssid: string = "iPhone (2)";
  //ssidPassword: string = "yoyo66666";

  @Input() wifiAuthInfoModel: WifiAuthInfoModel;

  value: string;

  //QR code string format
  //:A:B:C:D:E:F,G,H,I,J,K,L,M,N
  // A
  //Internal use
  a: string;
  //B
  qrCodeVersion: string;
  //C
  ssidPasswordLength: string;
  //D
  //Internal use
  d: string = '';
  //E
  // Internal use
  e: string = '0';
  //F
  // Internal use
  f: string = '';
  //G
  // Internal use
  g: string = '';
  //H
  ssidLength: string;
  //I
  ssidPlusPassword: string;
  //J
  utcTime: string;
  //K
  ssidEncodedBase64Length: string;
  //L
  ssidEncodedBase64: string;
  //M
  //pairingDetails: string;
  pairingCode: string;
  //N
  //Internal use
  n = 'vExternal';
  showQrCode: boolean;

  constructor(
    private activeModal: NgbActiveModal,
    private pocService: PocService
  ) {}

  ngOnInit() {}

  private generateCode() {
    this.pocService.postWifiAuthInformation(this.wifiAuthInfoModel).subscribe(
      (wifiAuthInfoModel) => {},
      (error) => {
        console.log(error);
      }
    );
    this.generateInfo();

    this.showQrCode = false;
    this.pairingCode = JSON.stringify(this.pairingDetails);
    let now = new Date().getTime().toString();
    this.utcTime = now.substr(0, now.length - 3);
    //QR code string format
    //:A:B:C:D:E:F,G,H,I,J,K,L,M,N
    this.constructValueForQRCode();
    console.log(this.value);
    this.showQrCode = true;
  }

  private generateInfo() {
    this.a = '';
    //B
    this.qrCodeVersion = 'V4';
    //C
    this.ssidPasswordLength =
      this.wifiAuthInfoModel.ssidPassword.length.toString();
    //D
    //Internal use
    this.d = '';
    //E
    // Internal use
    this.e = '0';
    //F
    // Internal use
    this.f = '';
    //G
    // Internal use
    this.g = '';
    //H
    this.ssidLength = this.wifiAuthInfoModel.ssid.length.toString();
    //I
    this.ssidPlusPassword =
      this.wifiAuthInfoModel.ssid + this.wifiAuthInfoModel.ssidPassword;
    //J
    this.utcTime;
    //K
    this.ssidEncodedBase64Length = btoa(
      this.wifiAuthInfoModel.ssid
    ).length.toString();
    //L
    this.ssidEncodedBase64 = btoa(this.wifiAuthInfoModel.ssid);
    //M
    //pairingDetails: string;
    this.pairingCode;
    //N
    //Internal use
    this.n = 'vExternal';
    this.showQrCode = false;
  }

  private constructValueForQRCode() {
    this.value =
      '' +
      // + A + ":"
      this.a +
      ':' +
      // + B + ":"
      this.qrCodeVersion +
      ':' +
      // + C +":"
      this.ssidPasswordLength +
      ':' +
      // + D + ":"
      this.d +
      ':' +
      // + E +":"
      this.e +
      ':,' +
      // + F + ","
      this.f +
      ',' +
      // + G + ","
      this.g +
      ',' +
      // + H + ","
      this.ssidLength +
      ',' +
      // + I + ","
      this.ssidPlusPassword +
      ',' +
      // + J+ ","
      this.utcTime +
      ',' +
      // + K + ","
      this.ssidEncodedBase64Length +
      ',' +
      // + L + ","
      this.ssidEncodedBase64 +
      ',' +
      // + M + ","
      this.pairingCode +
      ',' +
      // + N;
      this.n;
  }

  closeModal() {
    this.activeModal.close();
  }

  ssidValid() {
    return true;
  }

  ssidPasswordValid() {
    return true;
  }
}
