import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { IDocumentModalData } from '../base-file-preview.component';

@Component({
  selector: 'safesante-file-preview',
  templateUrl: './file-preview.component.html',
  styleUrls: ['./file-preview.component.css']
})
export class FilePreviewComponent  {


  @ViewChild('safesanteBaseFilePreview') filePreview;

  @Input() data: IDocumentModalData;

  @Output() closeEvent = new EventEmitter();


  @Output() mouseMove = new EventEmitter();

  @Output() makeCoordinatesZero = new EventEmitter();

  @Output() pdfClick = new EventEmitter();
  @Output() imgClick = new EventEmitter();

  pdfType = 'application/pdf';


  constructor() { }


  download() {
    this.filePreview.download();
  }

  printDocument() {
    this.filePreview.printDocument()
  }

  close() {
    this.closeEvent.emit();
  }

}
