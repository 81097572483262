import {InjectionToken} from '@angular/core';

export const SESSION_STORAGE = new InjectionToken<Storage>(
  'SessionStorageToken',
  typeof window !== 'undefined' && window.document
    ? {providedIn: 'root', factory: () => window.sessionStorage}
    : {
        providedIn: 'root', factory: () => ({
          length: 0,
          clear: () => null,
          getItem: () => null,
          key: () => null,
          removeItem: () => null,
          setItem: () => null,
        })
    }
);
