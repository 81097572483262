import { PatientService } from './../../patient/patient.service';
import { Router } from '@angular/router';
import { PairingInfoComponent } from './../pairing-info/pairing-info.component';
import { Component, OnInit } from '@angular/core';
import { PocService } from '../poc.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ReportsComponent } from '../reports/reports.component';
import {
  ConsultationModel,
  ConsultationStatus,
  PairingDetails,
  StationResponse,
  UserModel,
} from '../../model/safesante-ysera';

@Component({
  selector: 'safesante-matlc-pairing-device',
  templateUrl: './pairing-device.component.html',
  styleUrls: ['./pairing-device.component.scss'],
})
export class PairingDeviceComponent implements OnInit {
  deviceState = '';
  deviceStateEnum: string;

  lastConsultation: ConsultationModel;

  constructor(
    private pocService: PocService,
    private pairingInfoModal: NgbModal,
    private patientService: PatientService,
    private reportsModal: NgbModal,
    private router: Router
  ) {}

  ngOnInit() {
    if (this.pocService.getId()) {
      this.getDiviceStatus();
    } else {
      this.router.navigate(['/login']);
    }

    this.pocService.getLastTLC().subscribe(
      (consultation) => {
        console.log('lastConsultation: ' + JSON.stringify(consultation));
        this.lastConsultation = consultation;
      },
      () => {}
    );
  }

  private getDiviceStatus() {
    this.pocService.getDeviceStatus().subscribe((status: StationResponse) => {
      this.deviceStateEnum = status.devicePairingStatus;
      if (this.deviceStateEnum == 'PAIRED' && status.isActive) {
        this.deviceState = 'Connectée';
      } else if (this.deviceStateEnum == 'PAIRED' && !status.isActive) {
        this.deviceState = 'A reconnecter';
      } else if (
        this.deviceStateEnum == 'PREVIOUSLY_PAIRED' &&
        !status.isActive
      ) {
        this.deviceState = 'A reconnecter';
      } else if (this.deviceStateEnum == 'NOT_PAIRED' && !status.isActive) {
        this.deviceState = 'Non connectée';
      } else {
        this.deviceState = 'Non connectée';
        this.deviceStateEnum = 'NOT_PAIRED';
      }
    });
  }

  connectDevice() {
    this.pocService
      .getPairingDetails()
      .subscribe((pairingDetails: PairingDetails) => {
        console.log(pairingDetails);
        this.pocService.getWifiAuthInformation().subscribe(
          (wifiAuthInfoModel) => {
            const modalRef = this.pairingInfoModal.open(PairingInfoComponent);
            modalRef.componentInstance.pairingDetails = pairingDetails;
            modalRef.componentInstance.wifiAuthInfoModel = wifiAuthInfoModel;

            modalRef.result.then(
              () => {
                this.getDiviceStatus();
              },
              () => {
                this.return;
              }
            );
          },
          (error) => {
            console.log(error);
          }
        );
      });
  }

  resumeTLC() {
    this.pocService.resumeLastTLC().subscribe(() => {
      this.patientService.getConnectedUser().subscribe(
        (connectedUser: UserModel) => {
          sessionStorage.setItem('sessionUser', JSON.stringify(connectedUser));
          this.goToTeleconsultation();
        },
        (error) => {
          console.log(error);
        }
      );
    });
  }

  goToTeleconsultation() {
    setTimeout(() => {
      this.patientService.setConsultation(this.lastConsultation);
      const status = this.lastConsultation.status;
      if (status == ConsultationStatus.PATIENT_JOINED) {
        this.router.navigate(['/consultation']);
      } else {
        this.router.navigate(['/waiting-room']);
      }
    }, 500);
  }

  showReports() {
    const modalRef = this.reportsModal.open(ReportsComponent);
    modalRef.result.then(
      () => {
        return;
      },
      () => {
        console.log('close');
      }
    );
  }

  return() {
    this.pocService.logout();
  }
}
