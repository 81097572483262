import { HttpEventType } from '@angular/common/http';
import {
  AfterContentInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '@safesante/environments';
import { FilePreviewComponent } from '@safesante/file-preview';
import { SESSION_STORAGE, WINDOW } from '@safesante/utils';
import { FileUploader } from 'ng2-file-upload';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { ChatService,  MessageChatModel } from './chat.service';
import { WebsoketService } from './websoket.service';

@Component({
  selector: 'safesante-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.css'],
})
export class ChatComponent implements OnInit, AfterContentInit, OnDestroy {
  messagesStyle: any = {};
  uploadProgress = 0;
  sendMessagesStyle: any = {};
  sendingFile = false;
  chatUploadSubscription!: Subscription;
  sessionUser = this.sessionStorage.getItem('sessionUser') ? JSON.parse(<string>this.sessionStorage.getItem('sessionUser')) : null;

  role: string =
    this.sessionUser?.role;
  isPatient!: boolean;
  message!: string;
  fileUploadingName = '';
  status: any;

  uploader: FileUploader = new FileUploader({
    isHTML5: true,
  });

  subscription: any;

  private destroy$ = new EventEmitter();
  private socket = WebsoketService.connect(environment.chat);

  @ViewChild('inputElement', { static: false }) inputElement!: ElementRef;
  @ViewChild('messagInputEl', { static: false }) messagInputEl!: ElementRef;
  @ViewChild('downloadElement', { static: false }) downloadElement!: ElementRef;
  @Input() messages: MessageChatModel[] = [];
  @Input() consultationId: number;
  @Output() newMessageArrived = new EventEmitter();

  constructor(
    @Inject(SESSION_STORAGE) private sessionStorage: Storage,
    @Inject(WINDOW) private window: Window,
    private chatService: ChatService,
    private ngbModal: NgbModal,
    private deviceService: DeviceDetectorService
  ) {
    this.socket
      .pipe(
        switchMap((msgId) =>
          this.chatService.getMessage(this.consultationId, +msgId)
        )
      )
      .subscribe(
        (msg) => {
          if (!this.messages) {
            this.messages = [];
          }

          this.messages.push(msg);
          this.newMessageArrived.emit();
        },
        (err) => console.error(err),
        // eslint-disable-next-line no-restricted-syntax
        () => console.info('the observable stream is complete')
      );
  }

  ngOnInit() {
    this.setChatHeights();
    this.chatService
      .getMessages(this.consultationId)
      .subscribe((messages: MessageChatModel[]) => {
        messages?.forEach((message) => {
          if (!this.messages) {
            this.messages = [];
          }
          this.messages.push(message);
        });
      });
  }

  private setChatHeights() {
    if (!this.isPatient) {
      this.messagesStyle = {
        height: (this.window.innerHeight * 0.45).toString() + 'px',
      };

      this.sendMessagesStyle = {
        height: (this.window.innerHeight * 0.2).toString() + 'px',
      };
      return;
    }
    const isPortrait = this.window.innerWidth < this.window.innerHeight;
    const isMobile = this.deviceService.isMobile();
    if (!isMobile && !isPortrait) {
      this.messagesStyle = {
        height: (this.window.innerHeight * 0.75).toString() + 'px',
      };
      this.sendMessagesStyle = {
        height: (this.window.innerHeight * 0.25).toString() + 'px',
      };
    } else {
      if (isPortrait) {
        this.messagesStyle = {
          height: (this.window.innerHeight * 0.6 * 0.34).toString() + 'px',
        };
        this.sendMessagesStyle = {
          height: (this.window.innerHeight * 0.4 * 0.34).toString() + 'px',
        };
      } else {
        this.messagesStyle = {
          height: (this.window.innerHeight * 0.5).toString() + 'px',
        };
        this.sendMessagesStyle = {
          height: (this.window.innerHeight * 0.5).toString() + 'px',
        };
      }
    }
  }

  ngAfterContentInit() {
    setTimeout(() => this.messagInputEl.nativeElement.focus(), 500);
  }

  ngOnDestroy() {

    this.destroy$.next(null);
    this.destroy$.complete();
    this.socket.complete();

    this.status = 'web soket is closed';
    this.uploader.removeFromQueue[0];
  }


  sendMessage() {
    this.chatService
      .saveMessage(
        this.consultationId,
        this.generateId(),
        !this.isPatient,
        this.message
      )
      .subscribe(
        (msgId) => {
          this.socket.next(msgId);
          this.message = '';
        },
        (error) => console.error(error)
      );
  }

  getMessageFile(message: MessageChatModel) {
    this.chatService
      .getMessageFile(this.consultationId, message.id)
      .subscribe((response) => {
        const contentType = <string>response.headers.get('content-type');
        const file = new Blob([<BlobPart>response.body], { type: contentType });
        const modal = this.ngbModal.open(FilePreviewComponent, {
          size: 'lg',
          windowClass: 'safesante-matlc-file-preview',
        });
        modal.componentInstance.file = file;
        modal.componentInstance.contentType = contentType;
        modal.componentInstance.close = 'Fermer';
        modal.componentInstance.title = message.message;
        this.newMessageArrived.emit();
      });
  }

  cancelFileUpload() {
    if (this.chatUploadSubscription) {
      try {
        this.chatUploadSubscription.unsubscribe();
      } catch (error) {
        console.error(error);
      }
    }
    this.uploadProgress = 0;
    // this.uploader.cancelAll();
    this.sendingFile = false;
  }

  onSelectFiles() {
    this.inputElement.nativeElement.click();
  }

  onInputChange() {
    const fileItem = this.uploader.queue[0]._file;

    this.fileUploadingName = fileItem.name;

    let percentDone = 0;
    const msgId = this.generateId();
    let msgSent = false;
    this.sendingFile = true;
    this.chatUploadSubscription = this.chatService
      .saveFileMessage(
        this.consultationId,
        msgId,
        !this.isPatient,
        fileItem
      )
      .subscribe((event) => {
        if (event.type === HttpEventType.UploadProgress) {
          // This is an upload progress event. Compute and show the % done:
          percentDone = Math.round((100 * event.loaded) / event.total);
          this.uploadProgress = percentDone;
        } else {
          if (percentDone === 100 && !msgSent) {
            this.socket.next(msgId.toString());
            this.message = '';
            msgSent = true;
            setTimeout(() => {
              this.sendingFile = false;
              this.uploadProgress = 0;
              this.fileUploadingName = '';
            }, 250);
          }
        }
      });
    this.uploader.clearQueue();
  }

  downloadFile(message: MessageChatModel) {
    this.chatService
      .getMessageFile(this.consultationId, message.id)
      .subscribe((response) => {
        const contentType = response.headers.get('content-type');
        const file = new Blob([< BlobPart>response.body], { type: contentType });
        const blobUrl = URL.createObjectURL(file);
        const a: HTMLElement = this.downloadElement.nativeElement;
        a.setAttribute('href', blobUrl);
        a.setAttribute('target', '_blank');
        a.setAttribute('download', message.message);
        a.click();
      });
  }

  generateId(): number {
    this.messages ??= [];
    return this.messages.length != 0 ?  this.messages[this.messages.length - 1].id + 1 : 1;

  }

  @HostListener('window:resize', ['$event']) onResize() {
    this.setChatHeights();
  }
}
