import { PatientComponent } from './patient/patient.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { ConsultationComponent } from './consultation/consultation.component';
import { WaitingRoomComponent } from './waiting-room/waiting-room.component';
import { WaitingReportComponent } from './waiting-report/waiting-report.component';
import { CheckPaymentComponent } from './check-payment/check-payment.component';

const routes: Routes = [
  { path: 'patient', component: PatientComponent },

  {
    path: 'consultation',
    loadChildren: () =>
      import('./consultation/consultation.module').then(
        (m) => m.ConsultationModule
      ),
  },
  { path: 'consultation', component: ConsultationComponent },
  { path: 'waiting-room', component: WaitingRoomComponent },
  {
    path: 'patient/compte-rendu/:beneficiaryId',
    component: WaitingReportComponent,
  },
  { path: 'check-payment', component: CheckPaymentComponent }
];

@NgModule({
  imports: [
    RouterModule,
    RouterModule.forChild(routes),
    CommonModule],
  exports: [RouterModule]
})

export class PatientRoutingModule { }
