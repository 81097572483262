<div class="h-100 " style="background-image: url('../assets/background-color: whiteng'); background-size: cover; ">

  <div class="row  justify-content-left">
    <button class=" mt-3 anchor-button ml-5 text-center vcenter color-white background-transparent border-0 font13"
      (click)="return()">&lt;
      Accueil</button>
      
  </div>
  
  <div style="text-align:center" class="h-75 w-100 pl-4 pr-4 vcenter">
    <img width="300" class="mx-auto" alt="Safesante Logo" src="../assets/MaTLClogo.png">

    <h1>Espace pharmacie</h1>



    <h2 class="mx-auto">Etat de la box:

    </h2>

    <div
      [ngClass]="{'mx-auto round_shadow_box p-3': true, 'background-green': deviceStateEnum == 'PAIRED', 'background-red': deviceStateEnum == 'NOT_PAIRED', 'background-yellow': deviceStateEnum == 'PREVIOUSLY_PAIRED' }">
      {{deviceState}}
    </div>

    <div class="row m-4" style="text-align:center">
      <button *ngIf="deviceStateEnum!='PAIRED'" type="submit border-r-5"
        class="mx-auto color-white mx-auto shadow purplebackground cp font16 bold text-uppercase border-radius5"
        (click)="connectDevice()">connecter
        la
        box</button>
    </div>

    <div class="row m-4" style="text-align:center">
     <p *ngIf="lastConsultation" class="mx-auto font-weight-bold m-2"> Dernière consultation: pour le patient {{lastConsultation.customer.firstname}}  {{lastConsultation.customer.firstname}} le {{lastConsultation.appointment.start | date : 'dd/MM/yyyy'}} à
      {{lastConsultation.appointment.start | date : "HH:mm"}} </p>
      <button *ngIf="deviceStateEnum =='PAIRED' && lastConsultation && lastConsultation.status != 'DONE'
      && lastConsultation.status != 'PATIENT_LEFT' && lastConsultation.status != 'PRACTITIONER_LEFT' && lastConsultation.status != 'CANCELLED' " type="submit border-r-5"
        class="mx-auto color-white mx-auto shadow purplebackground cp bold border-radius5"
        (click)="resumeTLC()">Reprendre
        la téléconsultation</button>
    </div>

  </div>

  <div class="row m-2 justify-content-end" >
    <div class="col-3">

      <button type="submit border-r-5"
      class="mt-3 anchor-button ml-5 text-center vcenter blue background-transparent border-0 font18"
      (click)="showReports()">historique des ordonnances</button>
    </div>
  </div>
</div>