import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LOCAL_STORAGE } from '@safesante/utils';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  ConsultationModel, CustomerVisitFormModel, DelayModel, HealthRecordModel, PairingDetails, WifiAuthInfoModel
} from '../model/safesante-ysera';

@Injectable({
  providedIn: 'root',
})
export class PatientService {
  logoutSubject: Subject<any> = new Subject<any>();

  constructor(
    private http: HttpClient,
    @Inject(LOCAL_STORAGE) private localStorageService: Storage,
    private router: Router
  ) {}

  getId() {
    const sessionUser = sessionStorage.getItem('sessionUser');
    let result: number;
    if (sessionUser) {
      result = parseInt(JSON.parse(sessionStorage.getItem('sessionUser')).id);
    } else {
      result = null;
    }
    return result;
  }

  getPocId() {
    return 99999999999999999999999;
  }

  getConnectedUser() {
    return this.http.get('/api/authentication');
  }

  postFreeCustomerVisitDetails(customerVisitModel: CustomerVisitFormModel) {
    return this.http.post<ConsultationModel>(
      '/api/consultations/freeVisit',
      customerVisitModel
    );
  }

  postTokenStripe(token: string, idConsultation: number) {
    return this.http.post(
      '/api/patients/' +
        this.getId() +
        '/consultations/' +
        idConsultation +
        '/mtcCharge',
      token
    );
  }

  postStripePayout(idConsultation: number) {
    return this.http.post(
      '/api/patients/' +
        this.getId() +
        '/consultations/' +
        idConsultation +
        '/payout',
      null
    );
  }

  patchConsultationStatus(consultationId, status) {
    return this.http.patch(
      '/api/patients/' +
        this.getId() +
        '/consultations/' +
        consultationId +
        '/mtcStatus',
      status
    );
  }



  postWifiAuthInformation(wifiAuthInfoModel: WifiAuthInfoModel) {
    return this.http.post(
      '/api/devicePoc/' + this.getPocId() + '/wifiAuth',
      wifiAuthInfoModel
    );
  }

  postConsultationRtcId(idConsultation: number, easyrtcid: string) {
    return this.http.post(
      '/api/patients/' +
        this.getId() +
        '/consultations/' +
        idConsultation +
        '/rtcId',
      easyrtcid
    );
  }

  setConsultation(consultation: ConsultationModel) {
    this.localStorageService.setItem('consultation', JSON.stringify(consultation));
  }

  getConsultation(): ConsultationModel {
    const consultation = this.localStorageService.getItem('consultation');

    return consultation
      ? new ConsultationModel().deserialize(JSON.parse(consultation))
      : null;
  }



  getWifiAuthInformation() {
    return this.http.get<WifiAuthInfoModel>(
      '/api/devicePoc/' + this.getPocId() + '/wifiAuth'
    );
  }

  getLinkedFreeConsultation(code: string) {
    return this.http
      .get<ConsultationModel>('/api/consultations/free/' + code)
      .pipe(
        map((consultation) => new ConsultationModel().deserialize(consultation))
      );
  }

  getPairingDetails() {
    return this.http.get<PairingDetails>(
      '/api/devicePoc/' + this.getPocId() + '/pairingDetails'
    );
  }


  isConsultationOngoing(idConsultation: number) {
    return this.http.get<boolean>(
      '/api/patients/' +
        this.getId() +
        '/consultations/isOnGoingFree/' +
        idConsultation
    );
  }

  isConsultationDelayed(idConsultation: number) {
    return this.http.get<DelayModel>(
      '/api/patients/' +
        this.getId() +
        '/consultations/isDelayed/' +
        idConsultation
    );
  }

  hasPractitionerLeft(idConsultation: number) {
    return this.http.get<boolean>(
      '/api/patients/' +
        this.getId() +
        '/consultations/practitionerLeft/' +
        idConsultation
    );
  }

  getConsultationSV(consultationId: number) {
    return this.http
      .get<ConsultationModel[]>(
        '/api/patients/' + this.getId() + '/consultations/' + consultationId
      )
      .pipe(
        map((consultation) => new ConsultationModel().deserialize(consultation))
      );
  }

  getPrescriptionFile(prescriptionId: number) {
    return this.http.get(
      '/api/patients/' +
        this.getId() +
        '/prescriptions/' +
        prescriptionId +
        '/file',
      { responseType: 'arraybuffer', observe: 'response' }
    );
  }

  getCaresheetFile(caresheetId: number): Observable<any> {
    return this.http.get(
      '/api/patients/' + this.getId() + '/caresheets/' + caresheetId + '/file',
      { responseType: 'arraybuffer', observe: 'response' }
    );
  }

  getWorkQuitFile(workQuitId: number): Observable<any> {
    return this.http.get(
      '/api/patients/' + this.getId() + '/workQuits/' + workQuitId + '/file',
      { responseType: 'arraybuffer', observe: 'response' }
    );
  }

  getDocumentFile(documentId: number) {
    return this.http.get(
      '/api/patients/' + this.getId() + '/documents/' + documentId + '/file',
      { responseType: 'arraybuffer', observe: 'response' }
    );
  }
  getReportFile(consultationId: number, reportId: number) {
    return this.http.get(
      '/api/patients/' +
        this.getId() +
        '/consultations/' +
        consultationId +
        '/reports/' +
        reportId +
        '/file',
      { responseType: 'arraybuffer', observe: 'response' }
    );
  }
  getHealthRecord(): Observable<HealthRecordModel> {
    return this.http.get<HealthRecordModel>(
      '/api/patients/' + this.getId() + '/healthrecord'
    );
  }

  isReportReady(idConsultation: number) {
    return this.http.get<boolean>(
      '/api/patients/' +
        this.getId() +
        '/consultations/isReportReady/' +
        idConsultation
    );
  }
  checkPayment(sessionId: string, consultationId: number) {
    return this.http.get<boolean>(
      '/api/authentication/isMtcPaymendDone/' +
        consultationId +
        '?sessionId=' +
        sessionId
    );
  }

  setDelayNotified(consultationId: number): Observable<any> {
    return this.http.get(
      '/api/patients/' +
        this.getId() +
        '/consultations/' +
        consultationId +
        '/delayNotified/'
    );
  }

  postPaymentSession() {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'text/plain; charset=utf-8'
    );

    return this.http.post<string>(
      '/api/patients/' +
        this.getId() +
        '/consultations/' +
        this.getConsultation().id +
        '/paymentSession',
      {},
      { headers, responseType: 'text' as 'json' }
    );
  }

  logout() {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/x-www-form-urlencoded; charset=UTF-8'
    );
    this.http
      .get('/api/logout', { headers: headers, responseType: 'text' })
      .subscribe(() => {
        sessionStorage.clear();
        this.router.navigate(['/accueil']);
      });
    this.logoutSubject.next(null);
  }


  setConsultationId(consultationId: number) {
    this.localStorageService.setItem('consultationId', JSON.stringify(consultationId));
  }


  getConsultationId() : number{
    const consultationJSON = this.localStorageService.getItem('consultationId');
    const consultationId: number = consultationJSON? JSON.parse(consultationJSON): null;
    return consultationId;
  }

}
