import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  Input,
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'safesante-matlc-appointment-delay',
  templateUrl: './appointment-delay.component.html',
  styleUrls: ['./appointment-delay.component.scss'],
})
export class AppointmentDelayComponent implements AfterContentChecked {
  @Input() practitionerName: string;
  @Input() start: string;
  @Input() delay: number;

  constructor(
    private cdRef: ChangeDetectorRef,
    private activeModal: NgbActiveModal
  ) {}

  ngAfterContentChecked() {
    this.cdRef.detectChanges();
  }

  dismissModal() {
    this.activeModal.dismiss();
  }
}
