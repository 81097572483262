<div style="text-align:center; background-size: cover;" class="h-100 w-100 vcenter main-box">
  <div class="row home-main-rw">
    <div class="col-12 col-md-6 col-lg-6 left-col">
      <img
        width="300"
        class="mx-auto"
        alt="Safesante Logo"
        src="../assets/logo-safesante-blanc.png"
      />

      <h1>Bienvenue sur MaTeleconsult!</h1>

      <div class="row m-4" style="text-align: center">
        <button
          type="submit border-r-5"
          class="mx-auto color-white mx-auto shadow purplebackground cp font16 bold text-uppercase border-radius5"
          (click)="startTeleconsultation()"
        >
          Démarrez une téléconsultation avec un médecin
        </button>
      </div>
    </div>
    <div class="col-12 col-md-6 col-lg-6 right-col">
      <div class="home-login-img"><img src="/assets/home-image.png" /></div>
    </div>
  </div>

  <div class="footer-text">
    <p>
      MaTeleconsult n'est pas un service d'urgence. En cas d'urgence, appelez le
      15
    </p>
  </div>
</div>
