import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';

export interface MessageChatModel {
  id: number;
  message: string;
  date: Date;
  pathAttachment: string;
  idPatient: number;
  idPractitioner: number;
}

export interface ConsultationModel {
  id: number;
}


@Injectable({
  providedIn: 'root',
})
export class ChatService {


  constructor(private http: HttpClient) { }


  public saveMessage(
    consulationId: number,
    msgId: number,
    isPrac: boolean,
    msg: string
  ): Observable<string> {

    console.warn('message text : ' + msg);
    return this.http.post<string>(
      '/api/chat/' + consulationId + '/msg/' + msgId + '?isPrac=' + isPrac,
      msg
    );
  }

  public saveFileMessage(
    consulationId: number,
    msgId: number,
    isPrac: boolean,
    fileItem: File
  ): Observable<HttpEvent<unknown>> {
    return this.http.post < HttpEvent<unknown>>(
      '/api/chat/' + consulationId + '/file/' + msgId + '?isPrac=' + isPrac,
      new FormData().append('file', fileItem),
      { reportProgress: true, observe: 'events' }
    );
  }



  public getMessage(
    consulationId: number,
    msgId: number
  ): Observable<MessageChatModel> {
    return this.http.get<MessageChatModel>(
      '/api/chat/' + consulationId + '/msg/' + msgId
    );
  }

  public getMessages(consulationId: number): Observable<MessageChatModel[]> {
    return this.http.get<MessageChatModel[]>('/api/chat/' + consulationId);
  }

  getMessageFile(consulationId: number, msgId: number): Observable<HttpResponse<unknown>> {
    return this.http.get('/api/chat/' + consulationId + '/file/' + msgId, {
      responseType: 'arraybuffer',
      observe: 'response',
    });
  }
}
