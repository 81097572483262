<div class="row blue-curve-background">
    <div class="col-md-12 col-lg-8 offset-md-0 offset-lg-2">
      <div class="row py-5">
        <div class="col-12 text-center">
          <h1 class="black font-white">Bienvenue sur MaTeleconsult</h1>
        </div>
        
      </div>
      <div class="row mt-5">
        <div class="col-12">
          <ng-container>
            <div class="col-md-8 center-form">
              <div class="mainheading mb-5 color-black header-border text-center">
                <div class="row-md-3 mb-3 mt-3">
                  <h4> <b> Veuillez renseigner votre mot de passe:
                    </b></h4>
                </div>
              </div>
              <form #form="ngForm" class="text-center">
                <div class="col-md-12 text-center">
                  <div class="row-md-3 mb-3 text-center">
                    <label>Email</label>
                    <input type="email" class="form-control font18" id="usr-email" placeholder="Identifiant"
                      [(ngModel)]="email" name="email" disabled="true">
                  </div>
                  <div class="row-md-3 mb-3 w-100">
                    <label>Mot de passe</label>
                    <input type="password"
                      [ngClass]="{'form-control font18':true, 'invalid-input': !respectPasswordFormat}" id="usr-password"
                      placeholder="Mot de passe" [(ngModel)]="password" name="password">
                    <p
                      [ngClass]="{'text-left': true, 'text-green':passwordRespectsFormat(), 'text-red':!passwordRespectsFormat() }">
                      Votre mot de passe doit contenir au moins 8 caractères dont au moins une majuscule, une minuscule et
                      un
                      chiffre</p>
                  </div>
                  <div class="row-md-3 mb-3 w-100">
                    <label>Confirmation du mot de passe</label>
                    <input type="password" [ngClass]="{'form-control font18':true, 'invalid-input': !checkPasswords()}"
                      id="usr-password" placeholder="Confirmation du mot de passe" [(ngModel)]="password2"
                      name="password2">
                  </div>
  
                  <div class="row-md-3 mb-3 w-100">
                    <label> </label>
                    <button type="submit"
                      class="green-btn form-control color-white shadow purplebackground cp font16 bold text-uppercase mt-4"
                      (click)="validate()">Valider</button>
                  </div>
                </div>
              </form>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>