import { PatientService } from './../patient.service';
import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PairingDetails, VisitResponse } from '../../model/safesante-ysera';
import { PairingInfoComponent } from '../../poc/pairing-info/pairing-info.component';

@Component({
  selector: 'safesante-matlc-pairing-device-reminder',
  templateUrl: './pairing-device-reminder.component.html',
  styleUrls: ['./pairing-device-reminder.component.scss'],
})
export class PairingDeviceReminderComponent implements OnInit {
  constructor(
    private activeModal: NgbActiveModal,
    private pairingInfoModal: NgbModal,
    private patientService: PatientService
  ) {}

  @Input() visitResponse: VisitResponse;

  ngOnInit() {
    console.log('////////////////INIT////////////////');
  }

  boxConnected() {
    this.activeModal.close();
  }

  boxNotConnected() {}

  connectTheBox() {
    this.patientService
      .getPairingDetails()
      .subscribe((pairingDetails: PairingDetails) => {
        console.log(pairingDetails);

        this.patientService.getWifiAuthInformation().subscribe(
          (wifiAuthInfoModel) => {
            const modalRef = this.pairingInfoModal.open(PairingInfoComponent);
            modalRef.componentInstance.pairingDetails = pairingDetails;
            modalRef.componentInstance.wifiAuthInfoModel = wifiAuthInfoModel;

            modalRef.result.then(
              () => {
                return;
              },
              () => {
                return;
              }
            );
          },
          (error) => {
            console.log(error);
          }
        );
      });
  }

  closeModal() {
    this.activeModal.close();
  }
}
