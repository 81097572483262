<div class="row  justify-content-left">
    <button class=" mt-3 anchor-button ml-5 text-center vcenter background-transparent border-0 font13"
        (click)="return()">&lt;
        Accueil</button>
</div>
<div class="row text-center mt-3">
    <div class="mx-auto">
        <img width="150" class="mx-auto mb-2" alt="Safesante Logo" src="../assets/document-medical.png">

        <h3>Vos documents sont arrivés. Vous pouvez dès à présent les imprimer.</h3>
    </div>
</div>

<div class="row mm0  mr5" *ngIf="healthRecord && healthRecord.reports">
    <div class="col-sm-12">
        <div class="w-100 shadow backgroundwhite border-radius5 mb-3 float-left">
            <div class="pt-4 pl-4 pr-4">
                <div class="border-bottom py-2 text-left  text-uppercase d-flex flex-row align-items-center">
                    <div><img src="../../../assets/comptesrendus_100.png" alt="ordonnance" class="mr-2 smimg"></div>
                    <div>
                        <h5 class="regular font-grey text-uppercase">Compte-rendu</h5>
                    </div>
                </div>
            </div>
            <div id="comptesRendus" class="float-left w-100 pl-4 pr-4 text-left collapse show">
                <div class="w-100 float-left border-bottom pb-4 pt-4"
                    *ngFor="let report of healthRecord?.reports; let i = index">
                    <div class="float-left body-text">
                        <div class="color-light-blue mb-2">Délivré par le Dr. {{report.practitionerLastName}}
                            ({{report.practitionerSpeciality}}) le {{report.start | date : 'dd/MM/yyyy'}} à
                            {{report.start | date : "HH:mm"}}</div>
                    </div>
                    <div *ngIf="report.path" class="float-right text-uppercase" (click)="getReportFile(report)">
                        <h6 class="regular font-blue pointer"><i class="fa fa-paperclip mr-2"></i> fichier joint
                        </h6>
                    </div>
                </div>
                <div *ngIf="healthRecord.reports?.length <= 0">
                    <p> Aucun compte-rendu </p>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row mm0 p-2 mr5" *ngIf="healthRecord?.prescriptions || healthRecord?.caresheets || healthRecord?.workQuits">
    <div class="col-sm-12">
        <div class="w-100 shadow backgroundwhite border-radius5 mb-3 float-left">
            <div class="pt-4 pl-4 pr-4">
                <div class="border-bottom py-2 text-left text-uppercase d-flex flex-row align-items-center">
                    <div><img src="../../../assets/ordonnances_100.png"
                            alt="ordonnances digitales safesante teleconsultation" class="smimg mr-2"></div>
                    <div>
                        <h5 class="regular font-grey text-uppercase">documents</h5>
                    </div>
                </div>
            </div>
            <div id="documents" class="float-left w-100 pl-4 pr-4 text-left show">
                <div class="w-100 float-left border-bottom pb-4 pt-4"
                    *ngFor="let prescription of healthRecord.prescriptions; let i = index">
                    <div class="float-left body-text">
                        <div class="font18 bold body-text mb-2">Ordonnance #{{i + 1}}</div>
                        <div class="color-light-blue mb-2">Délivrée par le Dr.
                            {{getPractitionerLastName(prescription)}}
                            {{getPractitionerSpeciality(prescription)}} le
                            {{prescription.start | date : 'dd/MM/yyyy'}}</div>
                    </div>
                    <div *ngIf="prescription.path" class="float-right text-uppercase"
                        (click)="getPrescriptionFile(prescription)">
                        <h6 class="regular font-blue pointer"><i class="fa fa-paperclip mr-2"></i> fichier joint
                        </h6>
                    </div>
                </div>
                <div class="w-100 float-left border-bottom pb-4 pt-4"
                    *ngFor="let caresheet of healthRecord.caresheets; let i = index">
                    <div class="float-left body-text">
                        <div class="font18 bold body-text mb-2">Feuille de soins #{{i + 1}}</div>
                        <div class="color-light-blue mb-2">Délivrée par le Dr.
                            {{caresheet.practitioner.lastname}}
                            ({{caresheet.practitioner.speciality}}) le
                            {{caresheet.date | date : 'dd/MM/yyyy'}}</div>
                    </div>
                    <div *ngIf="caresheet.path" class="float-right text-uppercase"
                        (click)="getCaresheetFile(caresheet)">
                        <h6 class="regular font-blue pointer"><i class="fa fa-paperclip mr-2"></i> fichier joint
                        </h6>

                    </div>
                </div>
                <div class="w-100 float-left border-bottom pb-4 pt-4"
                    *ngFor="let workQuit of healthRecord.workQuits; let i = index">
                    <div class="float-left body-text">
                        <div class="font18 bold body-text mb-2">Avis d'arrêt de travail #{{i + 1}}</div>
                        <div class="color-light-blue mb-2">Délivré par le Dr.
                            {{workQuit.practitioner.lastname}}
                            ({{workQuit.practitioner.speciality}}) le
                            {{workQuit.date | date : 'dd/MM/yyyy'}}</div>
                    </div>
                    <div *ngIf="workQuit.path" class="float-right text-uppercase" (click)="getWorkQuitFile(workQuit)">
                        <h6 class="regular font-blue pointer"><i class="fa fa-paperclip mr-2"></i> fichier joint
                        </h6>

                    </div>
                </div>
                <div
                    *ngIf="healthRecord.prescriptions?.length <= 0 && healthRecord.caresheets?.length <= 0 && healthRecord.workQuit?.length <=0">
                    <p> Aucun document </p>
                </div>
            </div>
        </div>
    </div>
</div>