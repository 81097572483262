<div style="text-align:center; background-image: url('../assets/background-color: whiteng'); background-size: cover;"
    class="h-100 w-100 vcenter">

    <img width="300" class="mx-auto" alt="Safesante Logo" src="../assets/MaTLClogo.png">

    <div class="row text-center">
        <div class="mx-auto">
            <div class="col">
                <img height="300" class="mx-auto" alt="Safesante Logo" src="../assets/medecin-teleconsultation.png">
                <img width="150" class="mx-auto" alt="Safesante Logo" src="../assets/document-medical.png">
            </div>

        </div>
    </div>
    <div class="row text-center">
        <div class="mx-auto">
            <div class="col">
                <div class="spinner-border text-primary" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
        </div>
    </div>

    <h2 *ngIf="consultation?.practitioner" >Le Dr {{consultation?.practitioner?.lastname}} est en train de finaliser votre compte-rendu de téléconsultation.
        Nous vous invitons à patienter quelques instants afin d'obtenir vos documents.</h2>
</div>