import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSliderModule } from '@angular/material/slider';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { NgxLoadingModule } from 'ngx-loading';
import { CustomDatePickerComponent } from './custom-date-picker/custom-date-picker.component';
import { CustomTimePickerComponent } from './custom-time-picker/custom-time-picker.component';
import { CustomDateAdapterService } from './services/custom-date-adapter.service';
import { MatFormFieldModule } from '@angular/material/form-field';
import { DateAdapter, MatNativeDateModule } from '@angular/material/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
@NgModule({
  declarations: [CustomDatePickerComponent, CustomTimePickerComponent],
  imports: [
    CommonModule,
    FormsModule,
    NgbModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatInputModule,
    MatSliderModule,
    MatNativeDateModule,
    MatIconModule,
    MatButtonModule,
    MatAutocompleteModule,
    MatSelectModule,
    MatNativeDateModule,
    NgxExtendedPdfViewerModule,
    MatIconModule,
    MatButtonModule,
    NgxLoadingModule,
    MatDialogModule,
    MatTooltipModule,
  ],
  exports: [CustomDatePickerComponent, CustomTimePickerComponent],
  providers: [{ provide: DateAdapter, useClass: CustomDateAdapterService }],
})
export class SharedModule {}
