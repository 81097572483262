import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit, Input } from '@angular/core';
import { PatientService } from '../patient.service';
import { FilePreviewComponent } from '../file-preview/file-preview.component';
import { Caresheet } from '../../model/caresheet';
import {
  HealthRecordModel,
  ConsultationModel,
  PrescriptionModel,
  ReportModel,
} from '../../model/safesante-ysera';
import { WorkQuit } from '../../model/work-quit';

@Component({
  selector: 'safesante-matlc-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss'],
})
export class ReportComponent implements OnInit {
  healthRecord: HealthRecordModel;

  constructor(
    private patientService: PatientService,
    private ngbModal: NgbModal,
    private ngbActiveModal: NgbActiveModal
  ) {}

  @Input() consultation: ConsultationModel;

  ngOnInit() {
    this.patientService
      .getHealthRecord()
      .subscribe((healthRecord: HealthRecordModel) => {
        this.healthRecord = healthRecord;
      });
  }

  getPrescriptionFile(prescription: PrescriptionModel) {
    this.patientService
      .getPrescriptionFile(prescription.id)
      .subscribe((response) => {
        this.showFile(response);
      });
  }

  getCaresheetFile(caresheet: Caresheet) {
    this.patientService.getCaresheetFile(caresheet.id).subscribe((response) => {
      this.showFile(response);
    });
  }

  getWorkQuitFile(workquit: WorkQuit) {
    this.patientService.getWorkQuitFile(workquit.id).subscribe((response) => {
      this.showFile(response);
    });
  }

  private showFile(response: any) {
    let contentType = response.headers.get('content-type');
    let file = new Blob([response.body], { type: contentType });

    const modal = this.ngbModal.open(FilePreviewComponent, {
      size: 'lg',
      windowClass: 'app-file-preview',
    });
    modal.componentInstance.title = response.headers
      .get('content-disposition')
      .split(';')[1]
      .split('name')[1]
      .split('=')[1]
      .replace('.pdf_', '.pdf')
      .trim();

    modal.componentInstance.file = file;
    modal.componentInstance.contentType = contentType;
  }

  getReportFile(report: ReportModel) {
    this.patientService
      .getReportFile(report.idConsultation, report.id)
      .subscribe((response) => {
        let contentType = response.headers.get('content-type');
        let file = new Blob([response.body], { type: contentType });

        const modal = this.ngbModal.open(FilePreviewComponent, {
          size: 'lg',
          windowClass: 'app-file-preview',
        });
        modal.componentInstance.file = file;
        modal.componentInstance.ttitle = 'Compte-rendu' + report.id;

        modal.componentInstance.contentType = contentType;
      });
  }

  getPractitionerLastName(prescription: PrescriptionModel) {
    if (prescription && prescription.practitioner) {
      return prescription.practitioner.lastname;
    } else {
      return prescription.practitionerName;
    }
  }

  getPractitionerSpeciality(prescription: PrescriptionModel) {
    if (prescription && prescription.practitioner) {
      return '(' + prescription.practitioner.speciality + ')';
    } else {
      return '';
    }
  }

  return() {
    this.ngbActiveModal.close();
    this.patientService.logout();
  }
}
