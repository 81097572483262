<h2 mat-dialog-title class="modal-title">{{ data.title }}</h2>
<mat-dialog-content>{{ data.message }}</mat-dialog-content>
<mat-dialog-actions class="d-flex justify-content-end">
  <button class="text-uppercase" mat-button mat-dialog-close>
    {{ data.returnButton }}
  </button>
  <button
    class="text-uppercase ml-md-4"
    mat-flat-button
    color="warn"
    [mat-dialog-close]="true"
  >
    {{ data.confirmButton }}
  </button>
</mat-dialog-actions>
