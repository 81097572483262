import { MatButtonModule } from '@angular/material/button';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseFilePreviewComponent } from './base-file-preview.component';
import { FilePreviewDialogDecoratorComponent } from './file-preview-dialog-decorator/file-preview-dialog-decorator.component';
import { NgbProgressbarModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { FilePreviewComponent } from './file-preview/file-preview.component';

@NgModule({
    declarations: [BaseFilePreviewComponent, FilePreviewComponent, FilePreviewDialogDecoratorComponent],
    imports: [
        CommonModule,
        MatButtonModule,
        NgbProgressbarModule,
        NgxExtendedPdfViewerModule,
    ],
    exports: [BaseFilePreviewComponent,FilePreviewComponent, FilePreviewDialogDecoratorComponent]
})
export class FilePreviewModule {}
