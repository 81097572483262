import {InjectionToken} from '@angular/core';

export const LOCAL_STORAGE = new InjectionToken<Storage>(
  'LocalStorageToken',
  typeof window !== 'undefined' && window.document
    ? {providedIn: 'root', factory: () => window.localStorage}
    : {
      providedIn: 'root', factory: () => ({
        length: 0,
        clear: () => null,
        getItem: () => null,
        key: () => null,
        removeItem: () => null,
        setItem: () => null,
      })
    }
);
