import { ComponentType } from '@angular/cdk/overlay';
import { HttpEvent } from '@angular/common/http';
import { Injectable, TemplateRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BaseFilePreviewService {

  constructor(private dialog: MatDialog) {}



  displayDialog<T>(component: ComponentType<T> | TemplateRef<T>,     request: Observable<HttpEvent<never>>,
    documentType: string,
    documentId: number) {
    const options = {
      data: {
        documentId: documentId,

        documentType: documentType,
        request: request,
      },
      maxHeight: '90vh',
      maxWidth: '90vw',
      width: '900px',
      hasBackdrop: false,
    };
    return this.dialog.open(component, options);
   }
}
