<safesante-base-file-preview #safesanteBaseFilePreview [data]="data" (pdfClickEvent)="pdfClick.emit($event)" (imgClickEvent)="imgClick.emit($event)" (makeCoordinatesZero)="makeCoordinatesZero.emit()" (mouseMove)="mouseMove.emit($event)">
  <ng-content></ng-content>
  <div class="col-8 col-md-10 d-flex justify-content-start p-2">

    <button *ngIf="filePreview?.contentType?.includes(pdfType)" mat-stroked-button type="button"
      class="ml-3 mb-3 mb-md-0" (click)="printDocument()">
      Imprimer
    </button>
    <button mat-stroked-button type="button" class="ml-3 mb-3 mb-md-0" (click)="download()">
      Télécharger
    </button>
    <ng-content select="[actionsContent]"></ng-content>
  </div>
  <div *ngIf="!data.isEmbeded" class="col-4 col-md-2 d-flex justify-content-end p-2">
    <div>
      <button mat-flat-button color="warn" type="button" class="mr-3" (click)="close()">
        Fermer
      </button>
    </div>
  </div>
</safesante-base-file-preview>
<ng-content select="[infosContent]"></ng-content>
