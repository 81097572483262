import { PocService } from './../poc.service';
import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';
import { RegistrationConfirmationComponent } from '../registration-confirmation/registration-confirmation.component';
import { PocRegisterModel } from '../../model/safesante-ysera';

@Component({
  selector: 'safesante-matlc-register-poc',
  templateUrl: './register-poc.component.html',
  styleUrls: ['./register-poc.component.scss'],
})
export class RegisterPocComponent implements OnInit {
  error = false;

  email: string;

  password: string;

  password2: string;

  isFormSubmittedOnce = false;

  areSamePasswords = true;

  respectPasswordFormat = true;

  private token: string;

  constructor(
    public activeModal: NgbModal,
    private activatedRoute: ActivatedRoute,
    private route: ActivatedRoute,
    public ngbModal: NgbModal,
    private router: Router,
    private pocService: PocService
  ) {}

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.token = params['token'];
      console.log('got token = ' + this.token);

      this.pocService
        .getEmailForAccountValidation(this.token)
        .subscribe((email: string) => {
          this.email = email;
        });
    });
  }

  validate() {
    this.isFormSubmittedOnce = true;
    const pocRegisterModel = new PocRegisterModel();
    pocRegisterModel.password = this.password;
    pocRegisterModel.resetToken = this.token;
    if (this.formIsValid()) {
      this.pocService.validatePocAccount(pocRegisterModel).subscribe(
        (practitioner) => {
          const modal = this.activeModal.open(
            RegistrationConfirmationComponent,
            { size: 'lg' }
          );
          modal.result.then(
            () => {
              this.router.navigate(['/login']);
            },
            () => {
              this.router.navigate(['/login']);
            }
          );
          setTimeout(() => {
            this.router.navigate(['/login']);
          }, 5000);
        },
        () => {
          return;
        }
      );
    }
  }

  checkPasswords(): boolean {
    if (!this.isFormSubmittedOnce) return true;
    this.areSamePasswords =
      this.password == this.password2 &&
      this.password != null &&
      this.password2 != null &&
      this.password != '' &&
      this.password2 != '';
    return this.areSamePasswords;
  }

  passwordRespectsFormat(): boolean {
    const respectPasswordFormat =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z!@#$&()\\-`.+,/\"\]\[*$\d]{8,}$/.test(
        this.password
      );
    if (this.isFormSubmittedOnce) {
      this.respectPasswordFormat = respectPasswordFormat;
    }
    return this.respectPasswordFormat;
  }

  formIsValid(): boolean {
    const isFormValid: boolean =
      this.passwordRespectsFormat() && this.checkPasswords();
    return isFormValid;
  }
}
