import { Component, OnInit, Input } from '@angular/core';

import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
  ConsultationModel,
  HealthRecordModel,
  PrescriptionModel,
  ReportModel,
} from '../../model/safesante-ysera';
import { FilePreviewComponent } from '../../patient/file-preview/file-preview.component';
import { PocService } from '../poc.service';

@Component({
  selector: 'safesante-matlc-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss'],
})
export class ReportsComponent implements OnInit {
  healthRecords: HealthRecordModel[];
  constructor(
    private pocService: PocService,
    private ngbModal: NgbModal,
    private ngbActiveModal: NgbActiveModal
  ) {}

  @Input() consultation: ConsultationModel;

  ngOnInit() {
    console.log('//////////INIT/////////');
    this.pocService
      .getHealthRecords()
      .subscribe((healthRecords: HealthRecordModel[]) => {
        this.healthRecords = healthRecords;
      });
  }

  getPrescriptionFile(prescription: PrescriptionModel, consultationId: number) {
    this.pocService
      .getPrescriptionFile(prescription.id, consultationId)
      .subscribe((response) => {
        this.showFile(response);
      });
  }

  private showFile(response: any) {
    const contentType = response.headers.get('content-type');
    const file = new Blob([response.body], { type: contentType });

    const modal = this.ngbModal.open(FilePreviewComponent, {
      size: 'lg',
      windowClass: 'app-file-preview',
    });
    modal.componentInstance.title = response.headers
      .get('content-disposition')
      .split(';')[1]
      .split('name')[1]
      .split('=')[1]
      .replace('.pdf_', '.pdf')
      .trim();

    modal.componentInstance.file = file;
    modal.componentInstance.contentType = contentType;
  }

  getReportFile(report: ReportModel) {
    this.pocService
      .getReportFile(report.idConsultation, report.id)
      .subscribe((response) => {
        const contentType = response.headers.get('content-type');
        const file = new Blob([response.body], { type: contentType });

        const modal = this.ngbModal.open(FilePreviewComponent, {
          size: 'lg',
          windowClass: 'app-file-preview',
        });
        modal.componentInstance.file = file;
        modal.componentInstance.ttitle = 'Compte-rendu' + report.id;

        modal.componentInstance.contentType = contentType;
      });
  }

  getPractitionerLastName(prescription: PrescriptionModel) {
    if (prescription && prescription.practitioner) {
      return prescription.practitioner.lastname;
    } else {
      return prescription.practitionerName;
    }
  }

  getPractitionerSpeciality(prescription: PrescriptionModel) {
    if (prescription && prescription.practitioner) {
      return '(' + prescription.practitioner.speciality + ')';
    } else {
      return '';
    }
  }

  return() {
    this.ngbActiveModal.close();
  }
}
