<div class="modal-body file-preview">
    <div class="row">
        <div class="col">
            <div class="float-right">
                <span class="close" data-dismiss="modal" aria-label="Close" (click)="closeModal()">
                    <span aria-hidden="true"><i class="fas fa-times-circle pointer"></i></span>
                </span>
            </div>
        </div>
    </div>
    <div id="my-container" *ngIf="contentType.includes(pdfType)" class="ng-scope pdfobject-container mt-2">
        <ngx-extended-pdf-viewer [src]="pdfSource" useBrowserLocale="true" type="application/pdf" width="100%"
            style="overflow: auto;"></ngx-extended-pdf-viewer>
    </div>
    <div *ngIf="!contentType.includes(pdfType)" class="pdfobject-container">
        <div class="container">
            <img [src]="url" alt="attached image">
        </div>
    </div>
</div>
<div class="row justify-content-between file-preview">
    <div class="col" *ngIf="!isMobile">
        <button *ngIf="contentType.includes(pdfType)" type="button" class="btn btn-outline-dark float-left m-2 ml-3"
            (click)="printDocument()">Imprimer</button>
        <button type="button" class="btn btn-outline-dark m-2" (click)="download()">Télécharger</button>
    </div>
    <div class="col-4 text-center" *ngIf="isMobile">
        <button *ngIf="contentType.includes(pdfType)" type="button" class="btn btn-outline-dark float-left center-block mb-2"
            (click)="printDocument()">Imprimer</button>
    </div>
    <div class="col-4 text-center" *ngIf="isMobile">
        <button type="button" class="btn btn-outline-dark center-block mb-2" (click)="download()">Télécharger</button>
    </div>
    <div class="col" *ngIf="!isMobile">
        <button type="button" class="btn btn-outline-dark m-2 mr-3 float-right" (click)="closeModal()">Fermer</button>
    </div>
    <div class="col-4 text-center" *ngIf="isMobile">
        <button type="button" class="btn btn-outline-dark float-right center-block mb-2" (click)="closeModal()">Fermer</button>
    </div>
</div>