<div class="p-4">
    <div class="d-flex flex-column justify-content-center text-align-center">
      <div>
        <h1 class="bold">Votre demande de réinitialisation a été prise en compte</h1>
      </div>
      <div class="mt-3">
        <h2 class="light">Pour changer votre mot de passe, veuillez cliquer sur le lien que nous vous avons envoyé par
          mail.</h2>
      </div>
    </div>
    <div class="d-flex flex-row justify-content-center mt-4">
      <i class="fa fa-check-circle" style="color: #15dbcd; font-size:65pt;"></i>
    </div>
  </div>