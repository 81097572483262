<div class="row">
    <div class="col">
        <div class="float-right p-2 pointer">
            <span class="close pointer" data-dismiss="modal" aria-label="Close" (click)="return()">
                <span aria-hidden="true"><i class="fas fa-times-circle pointer"></i></span>
            </span>
        </div>
    </div>
</div>
<div class="row text-center mt-3">
    <div class="mx-auto m-2">
        <img width="150" class="mx-auto mb-2" alt="Safesante Logo" src="../assets/document-medical.png">
    </div>
</div>
<div class="row text-center mt-2" *ngIf="healthRecords && healthRecords.length == 0">
    <div class="mx-auto" >
        <h3 class="m-3">Aucune téléconsultation récente n'a été retrouvée. Les documents s'afficheront une fois que de téléconsultations auront été
            réalisées.</h3>
    </div>
</div>
<div *ngFor="let healthRecord of healthRecords; let i = index" style="border-bottom: 3px solid black;">
    <div class="row text-center mt-2" *ngIf="healthRecord && healthRecord.reports">
        <div class="mx-auto " >
            <h4 class="m-3" style="border-bottom: 3px solid black;">Consultation de {{healthRecord.firstname}} {{healthRecord.lastname}} réalisée le {{healthRecord.reports[0].start | date : 'dd/MM/yyyy'}} à
                {{healthRecord.reports[0].start | date : "HH:mm"}} </h4>
        </div>
    </div>

    <div class="row mm0  mr5" *ngIf="healthRecord && healthRecord.reports">
        <div class="col-sm-12">
            <div class="w-100 shadow backgroundwhite border-radius5 mb-3 float-left">
                <div class="pt-4 pl-4 pr-4">
                    <div class="border-bottom py-2 text-left  text-uppercase d-flex flex-row align-items-center">
                        <div><img src="../../../assets/comptesrendus_100.png" alt="ordonnance" class="mr-2 smimg"></div>
                        <div>
                            <h5 class="regular font-grey text-uppercase">Comptes-rendus</h5>
                        </div>
                    </div>
                </div>
                <div id="comptesRendus" class="float-left w-100 pl-4 pr-4 text-left collapse show">
                    <div class="w-100 float-left border-bottom pb-4 pt-4"
                        *ngFor="let report of healthRecord.reports; let i = index">
                        <div class="float-left body-text">
                            <div class="font18 bold body-text mb-2">Compte-rendu #{{i + 1}}</div>
                            <div class="color-light-blue mb-2">Délivré par le Dr. {{report.practitionerLastName}}
                                ({{report.practitionerSpeciality}}) le {{report.start | date : 'dd/MM/yyyy'}} à
                                {{report.start | date : "HH:mm"}}</div>
                        </div>
                        <div *ngIf="report.path" class="float-right text-uppercase" (click)="getReportFile(report)">
                            <h6 class="regular font-blue pointer"><i class="fa fa-paperclip mr-2"></i> fichier joint
                            </h6>
                        </div>
                    </div>
                    <div *ngIf="healthRecord.reports?.length <= 0">
                        <p> Aucun compte-rendu </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row mm0  mr5" *ngIf="healthRecord && healthRecord.prescriptions">
        <div class="col-sm-12">
            <div class="w-100 shadow backgroundwhite border-radius5 mb-3 float-left">
                <div class="pt-4 pl-4 pr-4">
                    <div class="border-bottom py-2 text-left text-uppercase d-flex flex-row align-items-center">
                        <div><img src="../../../assets/ordonnances_100.png"
                                alt="ordonnances digitales safesante teleconsultation" class="smimg mr-2"></div>
                        <div>
                            <h5 class="regular font-grey text-uppercase">ordonnances</h5>
                        </div>
                    </div>
                </div>
                <div id="ordonnances" class="float-left w-100 pl-4 pr-4 text-left show">
                    <div class="w-100 float-left border-bottom pb-4 pt-4"
                        *ngFor="let prescription of healthRecord.prescriptions; let i = index">
                        <div class="float-left body-text">
                            <div class="font18 bold body-text mb-2">Ordonnance #{{i + 1}}</div>
                            <div class="color-light-blue mb-2">Délivrée par le Dr.
                                {{getPractitionerLastName(prescription)}}
                                {{getPractitionerSpeciality(prescription)}} le
                                {{prescription.start | date : 'dd/MM/yyyy'}}</div>
                        </div>
                        <div *ngIf="prescription.path" class="float-right text-uppercase"
                            (click)="getPrescriptionFile(prescription, healthRecord.reports[0].idConsultation)">
                            <h6 class="regular font-blue pointer"><i class="fa fa-paperclip mr-2"></i> fichier joint
                            </h6>

                        </div>
                    </div>
                    <div *ngIf="healthRecord.prescriptions?.length <= 0 && !addingPrescription">
                        <p> Aucune ordonnance </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>