import {
  ChangeDetectorRef,
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbCarousel, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Subscription } from 'rxjs';
import { AppointmentDelayComponent } from '../../appointment-delay/appointment-delay.component';
import {
  ConsultationModel,
  Timer,
  DelayModel,
} from '../../model/safesante-ysera';
import { PatientService } from '../patient.service';

@Component({
  selector: 'safesante-matlc-waiting-room',
  templateUrl: './waiting-room.component.html',
  styleUrls: ['./waiting-room.component.scss'],
})
export class WaitingRoomComponent implements OnInit, OnDestroy {
  consultation: ConsultationModel;
  timer: Timer;

  startedConsultationSocket: boolean;

  @ViewChild('carouselExampleSlidesOnly', { static: false })
  carousel: NgbCarousel;
  cardNumber = 1;
  globalInstance: any;

  firstCarouselEvent = true;

  startedNotificationSubscription = false;

  private subscriptions: Subscription[] = [];
  interval: any;
  delayInterval: any;

  isMobile = false;

  constructor(
    private renderer: Renderer2,
    private activatedRoute: ActivatedRoute,
    private patientService: PatientService,
    private router: Router,
    private deviceDector: DeviceDetectorService,
    private changeDetector: ChangeDetectorRef,
    private appointmentDelayModal: NgbModal
  ) {}

  ngOnInit() {
    this.consultation = this.patientService.getConsultation();
    this.initWaitingRoom();
  }

  private initWaitingRoom() {
    this.initConsultationStartedChecker();
    this.timer = new Timer();
    this.timer.start();
    navigator.mediaDevices.enumerateDevices().then((devices) => {
      let hasCameraAndMicrophone =
        devices.findIndex((device) => device.kind == 'audioinput') != -1;
      hasCameraAndMicrophone =
        hasCameraAndMicrophone &&
        devices.findIndex((device) => device.kind == 'videoinput') != -1;
      if (!hasCameraAndMicrophone) {
        alert(
          "Le démarrage de la visio-conférence n'aboutit pas, veuillez vérifier que votre caméra et votre micro sont branchés à votre ordinateur, rafraîchir la page et réessayer"
        );
      }
    });
    this.isMobile = this.deviceDector.isMobile();
  }

  playAppointmentArrivesSoundTwo() {
    const audio = new Audio();
    audio.src = '../../../assets/audio/beep2.wav';
    audio.loop = false;
    //audio.load();
    audio.play();
  }

  ngAfterViewInit() {
    navigator.mediaDevices.enumerateDevices().then((devices) => {
      let hasCameraAndMicrophone =
        devices.findIndex((device) => device.kind == 'audioinput') != -1;
      hasCameraAndMicrophone =
        hasCameraAndMicrophone &&
        devices.findIndex((device) => device.kind == 'videoinput') != -1;
      if (!hasCameraAndMicrophone) {
        alert(
          "Nous n'arrivons pas à détecter vos équipents. Veuillez vérifier que votre caméra et votre micro sont branchés à votre ordinateur"
        );
      }
    });
  }

  goToTeleconsultation() {
    setTimeout(() => {
      this.patientService.setConsultationId(this.consultation.id);
      clearInterval(this.interval);
      clearInterval(this.delayInterval);
      this.router.navigate(['/consultation']);
    }, 500);
  }

  initConsultationStartedChecker() {
    this.interval = setInterval(() => {
      this.patientService
        .isConsultationOngoing(this.consultation.id)
        .subscribe((isConsultationOnGoing: boolean) => {
          if (isConsultationOnGoing) {
            this.goToTeleconsultation();
          }
        });
    }, 500);

    this.delayInterval = setInterval(() => {
      this.patientService
        .isConsultationDelayed(this.consultation.id)
        .subscribe((delay: DelayModel) => {
          if (delay && delay.delay) {
            clearInterval(this.delayInterval);
            this.showAppointmentDelay(delay);
          }
        });
    }, 3000);
  }

  showAppointmentDelay(delay: DelayModel) {
    const modalRef = this.appointmentDelayModal.open(AppointmentDelayComponent);
    modalRef.componentInstance.practitionerName =
      'Le Docteur ' + delay.practitionerName;
    modalRef.componentInstance.delay = delay.delay;
    modalRef.componentInstance.start = delay.start;
    modalRef.result.then(
      () => {
        return;
      },
      () => {
        return;
      }
    );
  }

  ngAfterViewChecked() {
    this.changeDetector.detectChanges();
  }

  ngOnDestroy() {
    clearInterval(this.interval);
    clearInterval(this.delayInterval);
    this.subscriptions.forEach((subscription) => {
      if (subscription) {
        try {
          subscription.unsubscribe();
        } catch (error) {
          console.log(error);
        }
      }
    });
  }

  onSlide() {
    if (this.cardNumber == 4) {
      this.cardNumber = 1;
    } else {
      this.cardNumber++;
    }
  }

  getProgressBarWidth() {
    if (this.carousel) {
      const width = this.carousel.activeId;
      if (this.firstCarouselEvent) {
        if (width == 'ngb-slide-1') {
          this.firstCarouselEvent = false;
        } else {
          return '25%';
        }
      }
      switch (width) {
        case 'ngb-slide-3':
          return '25%';
        case 'ngb-slide-0':
          return '50%';
        case 'ngb-slide-1':
          return '75%';
        case 'ngb-slide-2':
          return '100%';
      }
      return '25px';
    }
  }

  @HostListener('window:resize', ['$event']) onResize(event: any) {
    this.isMobile = this.deviceDector.isMobile();
  }
}
