import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MatMomentDateModule,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS
} from '@angular/material-moment-adapter';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FileUploadModule } from 'ng2-file-upload';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { ngxLoadingAnimationTypes, NgxLoadingModule } from 'ngx-loading';
import { AppointmentDelayComponent } from '../appointment-delay/appointment-delay.component';
import { PatientRoutingModule } from './/patient-routing.module';
import { CheckPaymentComponent } from './check-payment/check-payment.component';
import { ClockComponent } from './clock/clock.component';
import { PairingDeviceReminderComponent } from './pairing-device-reminder/pairing-device-reminder.component';
import { PatientComponent } from './patient/patient.component';
import { PrescriptionComponent } from './prescription/prescription.component';
import { ReportComponent } from './report/report.component';
import { WaitingReportComponent } from './waiting-report/waiting-report.component';
import { WaitingRoomComponent } from './waiting-room/waiting-room.component';

@NgModule({
    imports: [
        CommonModule,
        PatientRoutingModule,
        MatIconModule,
        MatRadioModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatDatepickerModule,
        MatNativeDateModule,
        FileUploadModule,
        MatMomentDateModule,
        FormsModule,
        ReactiveFormsModule,
        NgbModule,
        NgxExtendedPdfViewerModule,
        MatTooltipModule,
        NgxLoadingModule.forRoot({
            animationType: ngxLoadingAnimationTypes.cubeGrid,
            backdropBackgroundColour: 'rgba(0,0,0,0.1)',
            backdropBorderRadius: '4px',
            primaryColour: '#ffffff',
            secondaryColour: '#ffffff',
            tertiaryColour: '#ffffff',
        }),
    ],
    providers: [
        { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    ],
    declarations: [
        PatientComponent,
        WaitingRoomComponent,
        ClockComponent,
        PairingDeviceReminderComponent,
        PrescriptionComponent,
        ReportComponent,
        WaitingReportComponent,
        CheckPaymentComponent,
        AppointmentDelayComponent,
    ]
})
export class PatientModule {}
