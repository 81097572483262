import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ResetUserPasswordConfirmationComponent } from '../reset-user-password-confirmation/reset-user-password-confirmation.component';
import { PocService } from '../poc/poc.service';

@Component({
  selector: 'safesante-matlc-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss'],
})
export class PasswordResetComponent implements OnInit {
  email: string;

  formSubimittedOnce: boolean;

  constructor(
    private appService: PocService,
    private router: Router,
    private ngbModal: NgbModal
  ) {}

  ngOnInit() {}

  resetPassword() {
    this.formSubimittedOnce = true;
    if (this.emailValid()) {
      this.appService.resetPassword(this.email).subscribe(
        () => {
          this.showConfirmationModal();
        },
        () => {
          this.showConfirmationModal();
        }
      );
    }
  }

  private showConfirmationModal() {
    const modal = this.ngbModal.open(ResetUserPasswordConfirmationComponent, {
      size: 'lg',
    });
    modal.result.then(
      () => {
        this.router.navigate(['/accueil']);
      },
      () => {
        this.router.navigate(['/accueil']);
      }
    );
  }

  emailValid(): boolean {
    if (!this.formSubimittedOnce) return true;
    return this.email != null && this.email != '';
  }
}
