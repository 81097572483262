import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface ICancelModalData {
  title?: string;
  message?: string;
  returnButton?: string;
  confirmButton?: string;
}

@Component({
  selector: 'safesante-cancel',
  templateUrl: './cancel.component.html',
  styleUrls: ['./cancel.component.scss'],
})
export class CancelComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public data: ICancelModalData) {}

  ngOnInit() {
    this.data = {
      returnButton: 'annuler',
      confirmButton: 'confirmer',
      ...this.data,
    };
  }
}
