import { Component } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { LoginModel, UserModel } from '../../model/safesante-ysera';
import { PocService } from '../poc.service';

@Component({
  selector: 'safesante-matlc-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
  loginModel: LoginModel = new LoginModel();
  error = false;

  constructor(private router: Router, private pocService: PocService) {}

  signIn(form: NgForm) {
    if (form.valid) {
      this.pocService.login(this.loginModel).subscribe(
        () => {
          this.pocService
            .getConnectedUser()
            .subscribe((connectedUser: UserModel) => {
              sessionStorage.setItem(
                'sessionUser',
                JSON.stringify(connectedUser)
              );
              this.pocService.getPocProfile().subscribe((profile) => {
                this.pocService.setLocalPocProfile(profile);
                this.router.navigate(['/pharmacie']);
              });
            });
        },
        () => {
          this.error = true;
        }
      );
    }
  }
}
