import { Caresheet } from './caresheet';
import { WorkQuit } from './work-quit';

export enum AgreementType{
  NON_CONVENTIONNE="Non conventionné", SECTEUR_1="Conventionné secteur 1", SECTEUR_2="Conventionné secteur 2", SECTEUR_2_S_OPTAM="S2 - Sans OPTAM"
}

export enum FrequencyTypeVidal{
  PER_24_HOURS="toutes les 24h", PER_DAY="par jour", PER_HOUR="par heure",PER_MINUTE="par minute",PER_MONTH="par mois", PER_WEEK="par semaine",PER_YEAR="par an", THIS_DAY="ce jour",TYPE_44="toutes les 4h",TYPE_66="toutes les 6h"
}

export enum ConsultationStatus{
  COMING='COMING', PATIENT_JOINING='PATIENT_JOINING', PATIENT_JOINED='PATIENT_JOINED', PRACTITIONER_JOINED='PRACTITIONER_JOINED', ONGOING='ONGOING', PATIENT_ONGOING='PATIENT_ONGOING', PATIENT_LEFT='PATIENT_LEFT', PRACTITIONER_LEFT='PRACTITIONER_LEFT', DONE='DONE',IRL='IRL'
}

export enum UserType{
  ROLE_PATIENT='ROLE_PATIENT', ROLE_PRACTITIONER='ROLE_PRACTITIONER', ROLE_ADMIN='ROLE_ADMIN',  ROLE_SECRETARY = "ROLE_SECRETARY"
}


export class NodeModule {
  id: string;
}

export class ymaps {
  geometry: any;
  shape: any;
  templateLayoutFactory: any;
  Balloon: any;
  Placemark: any;
}

export class StationResponse {
  description: string;
  isActive: string;
  isPaired: string;
  locationIdentifier: string;
  devicePairingStatus: string;
}

export class PairingDetails {
  pairingCode: string;
  applicationServerUrl: string;
}

export class WifiAuthInfoModel {
  ssid: string;
  ssidPassword: string;
}

export class PasswordResetModel {
  resetToken: string;
  password: string;
}

export class CustomerModel {
  firstname: string;
  lastname: string;
  birthdate: string;
  age: number;
  gender: string = 'M';
  customerType: string;
  securiteSocialeNumber: string = '';
  ameNumber: string = '';
  insuranceCase: any;
  email: string;
  deserialize(customer: any): CustomerModel {
    Object.assign(this, customer);
    return this;
  }
}

export class CustomerVisitFormModel {
  customer: CustomerModel;

  appointmentCase: string;

  joinedTreatingPractitioner = true;

  treatingPractitionerFirstname: string;

  treatingPractitionerLastname: string;

  noTreatingPractitionier = false;

  forwardReportToTreatingPractitioner = true;

  constructor() {
    this.customer = new CustomerModel();
  }
}

export class VisitResponse {
  tytoIdentifier: string;
  patientUrl: string;
  isDeviceConnected: boolean;
  androidDeepLink: string;
  iosDeepLink: string;
  idConsultation: number;
}

export class PocRegisterModel {
  resetToken: string;
  password: string;
}

export class TytoPocModel {
  id: number;

  firstname: string;

  lastname: string;

  email: string;

  phone: string;

  tytoIdentifier: string;
  name: string;
}

export class LoginModel {
  email: string;
  password: string;
  rememberMe: boolean;

  deserialize(login: any): LoginModel {
    Object.assign(this, login);
    return this;
  }
}

export class PractitionerModel {
  id: number;
  firstname: string;
  lastname: string;
  birthdate: string;
  email: string;
  email2: string;
  phone: string;
  mobilePhone: string;
  speciality: string;
  videoconferencePrice: number;
  backofficeNote: String;
  agreementType: AgreementType;
  secretary: number;

  cptsMtc: boolean;

  domtom: boolean;

  formule: number;
  // workplaces: WorkplaceModel[];

  deserialize(practitioner: any): PractitionerModel {
    Object.assign(this, practitioner);
    return this;
  }
}

export class PractitionerRegisterModel {
  idPractitioner: number;
  email: string;
  password: string;
  password2: string;
  birthdate: string;
  deserialize(register: any): PractitionerRegisterModel {
    Object.assign(this, register);
    return this;
  }
}

export class AllergyModel {
  id: number;
  codeVidal: number;
  idVidal: string;
  name: string;
  categories: string;

  deserialize(allergy: any): AllergyModel {
    Object.assign(this, allergy);
    return this;
  }
}

export class AllergenMoleculeModel {
  id: number;
  codeVidal: number;
  idVidal: string;
  name: string;
  categories: string;

  deserialize(allergenMolecule: any): AllergenMoleculeModel {
    Object.assign(this, allergenMolecule);
    return this;
  }
}

export class PathologyModel {
  id: number;
  codeVidal: number;
  idVidal: string;
  name: string;

  deserialize(pathology: any): PathologyModel {
    Object.assign(this, pathology);
    return this;
  }
}
export class HistoryModel {
  id: number;
  codeVidal: number;
  idVidal: string;
  name: string;

  deserialize(history: any): HistoryModel {
    Object.assign(this, history);
    return this;
  }
}

export class VidalRootModel {
  title: string;
  id: string;
  updated: Date;
  entries: VidalEntryModel[];
  totalResults: number;
  itemsPerPage: number;
}

export class VidalEntryModel {
  id: string;
  shortId: number;
  updated: Date;
  title: string;
  summary: string;
  categories: string;
  dispensationPlace: string;
  pharmacistPrice: string;
  communityAgreement: string;
  isCeps: string;

  minUcdRangePrice: number;
  maxUcdRangePrice: number;
  refundRate: string;

  drugInSport: string;
  genericType: string;
  vigilance: string;

  indicatorVidalList: VidalIndicatorModel[];
}

export class VidalIndicatorModel {
  id: string;
  label: string;
}

export class VidalAlertsModel {
  title: string;
  id: string;
  updated: Date;
  entries: VidalAlertModel[];

  deserialize(alerts: any): VidalAlertsModel {
    Object.assign(this, alerts);
    this.entries = alerts.entries
      ? alerts.entries.map((alert) => new VidalAlertModel().deserialize(alert))
      : null;
    return this;
  }
}

export class VidalAlertModel {
  title: string;
  category: string;
  id: string;
  updated: Date;
  drugId: number;
  safetyAlert: boolean;
  type: string;
  dose: number;
  unitId: number;
  duration: number;
  code: string;
  marketStatus: string;
  ammType: string;
  durationType: string;
  frequencyType: string;
  route: number;

  maxContraIndicationSeverity: VidalAlertDetailsModel;
  maxAllergySeverity: VidalAlertDetailsModel;
  maxDrugDrugInteractionsSeverity: VidalAlertDetailsModel;
  maxPhysicoChemicalSeverity: VidalAlertDetailsModel;
  maxPrecautionSeverity: VidalAlertDetailsModel;
  maxRedundantInteractionsSeverity: VidalAlertDetailsModel;
  maxPosologySeverity: VidalAlertDetailsModel;
  maxProcreationSeverity: VidalAlertDetailsModel;

  deserialize(alerts: any): VidalAlertModel {
    Object.assign(this, alerts);
    this.maxContraIndicationSeverity = new VidalAlertDetailsModel().deserialize(
      alerts.maxContraIndicationSeverity
    );
    this.maxAllergySeverity = new VidalAlertDetailsModel().deserialize(
      alerts.maxAllergySeverity
    );
    this.maxDrugDrugInteractionsSeverity =
      new VidalAlertDetailsModel().deserialize(
        alerts.maxDrugDrugInteractionsSeverity
      );
    this.maxPhysicoChemicalSeverity = new VidalAlertDetailsModel().deserialize(
      alerts.maxPhysicoChemicalSeverity
    );
    this.maxPrecautionSeverity = new VidalAlertDetailsModel().deserialize(
      alerts.maxPrecautionSeverity
    );
    this.maxRedundantInteractionsSeverity =
      new VidalAlertDetailsModel().deserialize(
        alerts.maxRedundantInteractionsSeverity
      );
    this.maxPosologySeverity = new VidalAlertDetailsModel().deserialize(
      alerts.maxPosologySeverity
    );
    this.maxProcreationSeverity = new VidalAlertDetailsModel().deserialize(
      alerts.maxProcreationSeverity
    );
    return this;
  }
}

export class VidalAlertDetailsModel {
  severity: string;
  urlSuffix: string;
  name: string;
  title: string;

  constructor() {
    this.setTitle();
  }

  deserialize(alerts: any): VidalAlertDetailsModel {
    Object.assign(this, alerts);
    this.setTitle();
    return this;
  }

  setTitle() {
    this.title = this.name;
    if (this.severity == 'LEVEL_1') this.title = this.name + ', niveau 1';
    if (this.severity == 'LEVEL_2') this.title = this.name + ', niveau 2';
    if (this.severity == 'LEVEL_3') this.title = this.name + ', niveau 3';
    if (this.severity == 'LEVEL_4') this.title = this.name + ', niveau 4';
  }
}

export class TreatmentModel {
  id: number;
  idPrescription: number;
  start: string;
  ending: string;
  prescribed: boolean;
  idVidal: string;
  shortIdVidal: number;
  vmpIdVidal: string;
  vmpName: string;
  isProduct = true;
  name: string;
  quantity: number = null; //dose
  quantityMorning: number = 0;
  quantityNoon: number = 0;
  quantityEvening: number = 0;
  quantityBedtime: number = 0;
  quantityNight: number = 0;
  quantityType: string;
  quantityTypeLabel: string;
  duration: number;
  durationType: string;
  durationTypeLabel: string;
  frequencyType: string = null;
  frequencyTypeLabel: string;
  route: string;
  routeLabel: string;
  indication: string;
  indicationLabel: string;
  mealTime: string;
  ald: boolean;
  comments: string;
  object: string;
  current: boolean;
  practitionerName: string;
  patientName: string;
  note: string;
  longTerm: boolean;
  isOrdonnance: boolean;
  isStup: boolean;
  summary: string;
  posologyWrittenOut: string;

  //product detail response, Cette donnée n'est pas posté pour la securisation ni sauvegardée en base de donnée
  metadata: VidalRootModel;
  vmp: VidalEntryModel;
  product: VidalEntryModel;
  units: VidalEntryModel[];
  routes: VidalEntryModel[];
  posology: VidalEntryModel[];
  indications: VidalEntryModel[];
  indicators: VidalEntryModel[];
  restrictedPrescriptions: number[];
  alerts: VidalAlertModel;

  constructor(metadata: any) {
    if (metadata != null) {
      this.setMetadata(metadata);
    }
  }

  deserialize(prescriptionLine: any): TreatmentModel {
    Object.assign(this, prescriptionLine);
    return this;
  }

  // getProduct() {
  //   return this.metadata ? this.metadata.entries.filter(product => product.categories.indexOf('PRODUCT') >= 0
  //   || product.categories.indexOf('VMP') >= 0)[0] : [];
  // }
  //
  // getProductPackage() {
  //   return this.metadata ? this.metadata.entries.filter(product => product.categories.indexOf('PACKAGE') >= 0) : [];
  // }
  //
  // getPharmacyPackage() {
  //   let pharmacyPackage = this.getProductPackage().filter(currentPackage => currentPackage.dispensationPlace === 'PHARMACY');
  //   return pharmacyPackage.length > 0 ? pharmacyPackage[0] : null;
  // }

  getQuantity() {
    if (this.quantity) return this.quantity;
    return (
      this.quantityMorning +
      this.quantityNoon +
      this.quantityEvening +
      this.quantityBedtime +
      this.quantityNight
    );
  }

  setMetadata(metadata) {
    this.metadata = metadata;
    this.setMetadataProduct();
    this.setMetadataVmp();
    this.setMetadataUnits();
    this.setMetadataRoutes();
    this.setMetadataPosology();
    this.setMetadataIndications();
    this.setMetadataIndicators();
    this.setMetadataRestrictedPrescriptions();
    if (this.product) {
      this.idVidal = this.product.id;
      this.shortIdVidal = this.product.shortId;
      this.name = this.product.title;
      this.isProduct = true;
      this.summary = this.product.summary;
    }
    if (this.vmp) {
      this.vmpIdVidal = this.vmp.id;
      this.vmpName = this.vmp.title;
      this.isProduct = false;
      this.summary = this.vmp.summary;
    }
  }

  setMetadataProduct() {
    this.product = this.metadata
      ? this.metadata.entries.find((entry) =>
          entry.categories.includes('PRODUCT')
        )
      : null;
  }

  setMetadataVmp() {
    this.vmp = this.metadata
      ? this.metadata.entries.find((entry) => entry.categories.includes('VMP'))
      : null;
  }

  setMetadataUnits() {
    this.units = this.metadata
      ? this.metadata.entries.filter((entry) =>
          entry.categories.includes('PRESCRIPTION_UNIT')
        )
      : [];
  }

  setMetadataRoutes() {
    this.routes = this.metadata
      ? this.metadata.entries.filter((entry) =>
          entry.categories.includes('ROUTE')
        )
      : [];
  }

  setMetadataPosology() {
    this.posology = this.metadata
      ? this.metadata.entries.filter((entry) =>
          entry.categories.includes('POSOLOGY')
        )
      : [];
  }

  setMetadataIndications() {
    this.indications = this.metadata
      ? this.metadata.entries.filter((entry) =>
          entry.categories.includes('INDICATION')
        )
      : [];
  }

  setMetadataIndicators() {
    this.indicators = this.metadata
      ? this.metadata.entries.filter((entry) =>
          entry.categories.includes('INDICATORS')
        )
      : [];
    this.isStup = this.indicators.some((indicator) =>
      indicator.indicatorVidalList.some(
        (i) => i.id == '63' || i.id == '62' || i.id == '15'
      )
    );
  }

  setMetadataRestrictedPrescriptions() {
    this.restrictedPrescriptions = this.metadata
      ? this.metadata.entries
          .filter((entry) =>
            entry.categories.includes('RESTRICTED_PRESCRIPTIONS')
          )
          .map((entry) => entry.shortId)
      : [];
  }

  getRefundRate() {
    return this.metadata
      ? this.metadata.entries.filter(
          (entry) => entry.categories.indexOf('PACKAGE') >= 0
        ).length > 0
        ? this.metadata.entries.filter(
            (product) => product.categories.indexOf('PACKAGE') >= 0
          )[0].refundRate
        : this.product.refundRate
      : null;
  }

  getCommunityAgreement() {
    return this.metadata
      ? this.metadata.entries.filter(
          (entry) => entry.categories.indexOf('PACKAGE') >= 0
        ).length > 0
        ? this.metadata.entries.filter(
            (product) => product.categories.indexOf('PACKAGE') >= 0
          )[0].communityAgreement
        : this.product.communityAgreement
      : null;
  }

  isCeps() {
    return this.metadata
      ? this.metadata.entries.filter(
          (entry) => entry.categories.indexOf('PACKAGE') >= 0
        ).length > 0
        ? this.metadata.entries.filter(
            (product) => product.categories.indexOf('PACKAGE') >= 0
          )[0].isCeps
        : this.product.isCeps
      : null;
  }

  calculNbPrise(): number {
    if (
      !this.quantity &&
      (!this.quantityMorning ||
        !this.quantityNoon ||
        !this.quantityEvening ||
        !this.quantityNight) &&
      this.frequencyType === ''
    ) {
      return 0;
    }
    let quantity = this.quantity
      ? this.quantity
      : this.quantityMorning +
        this.quantityNoon +
        this.quantityEvening +
        this.quantityNight;
    let duration = this.duration ? this.duration : 30;
    let frequency: number;
    switch (this.frequencyType) {
      case 'PER_24_HOURS':
        frequency = 1;
        break;
      case 'PER_DAY':
        frequency = 1;
        break;
      case 'PER_HOUR':
        frequency = 24;
        break;
      case 'PER_MINUTE':
        frequency = 24 * 60;
        break;
      case 'PER_MONTH':
        frequency = 30;
        break;
      case 'PER_WEEK':
        frequency = 7;
        break;
      case 'PER_YEAR':
        frequency = 365;
        break;
      case 'THIS_DAY':
        frequency = 1;
        break;
      case 'TYPE_44':
        frequency = 4;
        break;
      case 'TYPE_66':
        frequency = 6;
        break;
      default:
        frequency = 0;
    }

    let durationType: number;
    switch (this.durationType) {
      case 'DAY':
        durationType = 1;
        break;
      case 'HOUR':
        durationType = 1 / 24;
        break;
      case 'MINUTE':
        durationType = (1 / 24) * 60;
        break;
      case 'MONTH':
        durationType = 30;
        break;
      case 'WEEK':
        durationType = 7;
        break;
      case 'YEAR':
        durationType = 365;
        break;
      default:
        durationType = 1;
    }
    return quantity * frequency * duration * durationType;
  }

  calculPrix(): number {
    if (this.product) {
      if (this.isProduct) {
        let prixUcd = this.product.minUcdRangePrice;
        if (!prixUcd) {
          return -1;
        }
        return +(this.calculNbPrise() * prixUcd).toFixed(2);
      }
    }
    return -1;
  }

  getPosology() {
    let posology: string = '';
    if (this.quantity) {
      posology = 'Posologie : ';
      if (!this.note) {
        posology +=
          this.quantity +
          ' ' +
          this.quantityTypeLabel +
          ' ' +
          FrequencyTypeVidal[this.frequencyType];
        if (this.duration && this.durationTypeLabel)
          posology +=
            ' pendant ' + this.duration + ' ' + this.durationTypeLabel;
      } else {
        posology +=
          this.quantity + ' ' + FrequencyTypeVidal[this.frequencyType];
        if (this.duration && this.durationTypeLabel)
          posology +=
            ' pendant ' + this.duration + ' ' + this.durationTypeLabel;
      }
    }
    if (
      this.quantityMorning ||
      this.quantityNoon ||
      this.quantityEvening ||
      this.quantityNight
    ) {
      posology = 'Posologie : ';
      if (!this.note) {
        if (this.quantityMorning)
          posology +=
            this.quantityMorning + ' ' + this.quantityTypeLabel + ' le matin ';
        if (this.quantityNoon)
          posology +=
            this.quantityNoon + ' ' + this.quantityTypeLabel + ' le midi ';
        if (this.quantityEvening)
          posology +=
            this.quantityEvening + ' ' + this.quantityTypeLabel + ' le soir ';
        if (this.quantityBedtime)
          posology +=
            this.quantityBedtime +
            ' ' +
            this.quantityTypeLabel +
            ' au coucher ';
        if (this.quantityNight)
          posology +=
            this.quantityNight +
            ' ' +
            this.quantityTypeLabel +
            ' durant la nuit ';
        if (this.duration && this.durationTypeLabel)
          posology += 'pendant ' + this.duration + ' ' + this.durationTypeLabel;
      } else {
        if (this.quantityMorning)
          posology += this.quantityMorning + ' le matin ';
        if (this.quantityNoon) posology += this.quantityNoon + ' le midi ';
        if (this.quantityEvening)
          posology += this.quantityEvening + ' le soir ';
        if (this.quantityBedtime)
          posology += this.quantityBedtime + ' au coucher ';
        if (this.quantityNight)
          posology += this.quantityNight + ' durant la nuit ';
        if (this.duration && this.durationTypeLabel)
          posology += 'pendant ' + this.duration + ' ' + this.durationTypeLabel;
      }
    }
    return posology;
  }
}

export class PrescriptionModel {
  id: number;
  practitioner: PractitionerModel;
  customer: CustomerModel;
  object: string;
  path: string;
  start: string;
  isOrdonnance: boolean;
  versionVidal: string;
  treatments: TreatmentModel[];
  isUploaded: boolean;
  practitionerName: string;

  deserialize(prescription: any): PrescriptionModel {
    Object.assign(this, prescription);
    this.practitioner = new PractitionerModel().deserialize(
      prescription.practitioner
    );
    this.customer = new CustomerModel().deserialize(prescription.customer);
    this.treatments = prescription.treatments
      ? prescription.treatments.map((treatment) =>
          new TreatmentModel(null).deserialize(treatment)
        )
      : null;
    return this;
  }

  nombreDeLigneCalcul(): number {
    return this.treatments.reduce(
      (memo, item) => memo + (item.calculPrix() >= 0 ? 1 : 0),
      0
    );
  }

  calculPrixOrdonance(): number {
    return +this.treatments
      .reduce(
        (memo, item) => memo + (item.calculPrix() >= 0 ? item.calculPrix() : 0),
        0
      )
      .toFixed(2);
  }
}

export class HealthRecordModel {
  id: number;
  gender: string;
  firstname: string;
  lastname: string;
  birthdate: string;
  birthPlace: string;
  age: number;
  weight: number;
  height: number;
  breastFeeding: string;
  breastFeedingStartDate: string;
  nbAmenorrhea: number;
  lastPeriod: string;
  creatinine: number;
  creatinineMeasure: string;
  clearance: number;
  hepaticInsufficiency: string;
  allergies: AllergyModel[];
  allergenMolecules: AllergenMoleculeModel[];
  pathologies: PathologyModel[];
  histories: HistoryModel[];
  treatments: TreatmentModel[];
  workQuits: WorkQuit[];
  caresheets: Caresheet[];
  prescriptions: PrescriptionModel[];
  reports: ReportModel[];
  notes: string;
  documents: DocumentModel[];
  // files : string[];
  // pathTreatments : string;

  // diagnostics : DiagnosisModel[];

  constructor() {
    this.allergies = [];
    this.allergenMolecules = [];
    this.pathologies = [];
    this.histories = [];
    this.treatments = [];
    this.documents = [];
    // this.prescriptions = [];
    // this.diagnostics = [];
  }

  deserialize(healthRecord: any): HealthRecordModel {
    Object.assign(this, healthRecord);
    this.allergies = healthRecord
      ? healthRecord.allergies
        ? healthRecord.allergies.map((allergy) =>
            new AllergyModel().deserialize(allergy)
          )
        : null
      : null;
    this.allergenMolecules = healthRecord
      ? healthRecord.allergenMolecules
        ? healthRecord.allergenMolecules.map((allergenMolecules) =>
            new AllergenMoleculeModel().deserialize(allergenMolecules)
          )
        : null
      : null;
    this.pathologies = healthRecord
      ? healthRecord.pathologies
        ? healthRecord.pathologies.map((pathologies) =>
            new PathologyModel().deserialize(pathologies)
          )
        : null
      : null;
    this.histories = healthRecord
      ? healthRecord.histories
        ? healthRecord.histories.map((histories) =>
            new HistoryModel().deserialize(histories)
          )
        : null
      : null;
    this.treatments = healthRecord
      ? healthRecord.treatments
        ? healthRecord.treatments.map((treatments) =>
            new TreatmentModel(null).deserialize(treatments)
          )
        : null
      : null;
    this.prescriptions = healthRecord
      ? healthRecord.prescriptions
        ? healthRecord.prescriptions.map((prescriptions) =>
            new PrescriptionModel().deserialize(prescriptions)
          )
        : null
      : null;
    this.prescriptions = healthRecord
      ? healthRecord.documents
        ? healthRecord.documents.map((documents) =>
            new DocumentModel().deserialize(documents)
          )
        : null
      : null;
    this.workQuits = healthRecord
      ? healthRecord.workQuits
        ? healthRecord.workQuits.map((workQuits) =>
            new Caresheet().deserialize(workQuits)
          )
        : null
      : null;

    // this.diagnostics = healthRecord.diagnostics.map(diagnostics => new DiagnosisModel().deserialize(diagnostics));
    return this;
  }
}

export class DocumentModel {
  id: number;
  name: any;
  practitionerName: string;
  patientId: number;
  start: string;
  path: string;
  deserialize(document: any): DocumentModel {
    Object.assign(this, document);
    return this;
  }
}

export class ReportModel {
  id: number;
  idConsultation: number;
  start: string;
  practitionerLastName: string;
  practitionerSpeciality: string;
  path: string;
  report: string;
  monitoring: string;
  conclusion: string;

  deserialize(report: any): ReportModel {
    Object.assign(this, report);
    return this;
  }
}

export class AppointmentModel {
  id: number;
  present: boolean;
  paid: boolean;
  customer: CustomerModel;
  practitioner: PractitionerModel;
  workplace: WorkplaceModel;
  start: string;
  ending: string;
  motive: MotiveModel;
  comments: string;
  userType: string;
  madeAt: string;
  placeTypes: string[];
  isMtc: boolean;

  clinicianURL: string;

  patientURL: string;

  deserialize(appointment: any): AppointmentModel {
    Object.assign(this, appointment);
    this.customer = new CustomerModel().deserialize(appointment.customer);
    this.practitioner = new PractitionerModel().deserialize(
      appointment.practitioner
    );
    this.workplace = new WorkplaceModel().deserialize(appointment.workplace);
    this.motive = new MotiveModel().deserialize(appointment.motive);
    return this;
  }
}

export class Location {
  lat: number;
  lng: number;

  deserialize(location: any): Location {
    Object.assign(this, location);
    return this;
  }
}

export class Address {
  street: string;
  zipcode: number;
  city: string;
  location: Location = new Location();

  deserialize(address: any): Address {
    Object.assign(this, address);
    this.location = address
      ? new Location().deserialize(address.location)
      : null;
    return this;
  }

  equals(address: Address): boolean {
    if (this.location && address.location)
      return (
        this.location.lat == address.location.lat &&
        this.location.lng == address.location.lng
      );
    return (
      this.street == address.street &&
      this.zipcode == address.zipcode &&
      this.city == address.city
    );
  }

  toString(): string {
    if (!this.street && !this.zipcode && !this.city) return '';
    return this.street + ', ' + this.zipcode + ' ' + this.city;
  }
}

export class MotiveModel {
  id: number;
  name: string;
  appointmentDuration: number;
  color: string;
  active: boolean;
  isTeleconsultation: boolean;

  deserialize(motive: any): MotiveModel {
    Object.assign(this, motive);
    return this;
  }
}

export class WorkplaceModel {
  id: number;
  address: Address = new Address();
  type: string;
  color: string;
  accessibility: string;

  deserialize(workplace: any): WorkplaceModel {
    if (workplace) {
      Object.assign(this, workplace);
      this.address = new Address().deserialize(workplace.address);
      return this;
    }
  }

  equals = (workplace: WorkplaceModel): boolean => {
    return (
      workplace.type == this.type && workplace.address.equals(this.address)
    );
  };

  getTypeToString() {
    if (this.type == 'OFFICE') return 'Cabinet';
    if (this.type == 'CLINIC') return 'Clinique';
    if (this.type == 'HOSPITAL') return 'Hôpital';
    if (this.type == 'OTHER') return 'Autre';
  }
}

export class MessageChatModel {
  id: number;
  message: string;
  date: Date;
  pathAttachment: string;
  idPatient: number;
  idPractitioner: number;

  deserialize(message: any): MessageChatModel {
    Object.assign(this, message);
    return this;
  }
}

export class DelayModel {
  consultationId: number;

  practitionerName: string;

  delay: number;
  start: string;
}

export class ConsultationModel {
  id: number;
  start: Date;
  ending: Date;
  room: string;
  practitioner: PractitionerModel;
  customer: CustomerModel;
  healthRecord: HealthRecordModel;
  status: string;
  appointment: AppointmentModel;
  duration: number;
  prescriptions: PrescriptionModel[];
  messages: MessageChatModel[];
  report: ReportModel;
  price: number;
  delay: number;
  delayNotified: boolean;
  treated: boolean;

  isShown: boolean;

  patientRtcId: string;

  practitionerRtcId: string;

  deserialize(consultation: any): ConsultationModel {
    Object.assign(this, consultation);
    this.practitioner = consultation.practitioner
      ? new PractitionerModel().deserialize(consultation.practitioner)
      : null;
    this.customer = new CustomerModel().deserialize(consultation.customer);
    this.healthRecord = new HealthRecordModel().deserialize(
      consultation.healthRecord
    );
    this.appointment = new AppointmentModel().deserialize(
      consultation.appointment
    );
    this.prescriptions = consultation.prescriptions
      ? consultation.prescriptions.map((prescription) =>
          new PrescriptionModel().deserialize(prescription)
        )
      : null;
    this.messages = consultation.messages
      ? consultation.messages.map((message) =>
          new MessageChatModel().deserialize(message)
        )
      : null;
    this.report = new ReportModel().deserialize(consultation.report);
    return this;
  }
}

export class UserModel {
  id: number;
  email: string;
  role: string;

  deserialize(user: any): UserModel {
    Object.assign(this, user);
    return this;
  }
}

export class Timer {
  private _minutes: number = 0;
  private _secondes: number = 0;
  private _totalSecondes: number = 0;
  private _timer: any;
  getMinutes(): number {
    return this._minutes;
  }
  getSecondes(): number {
    return this._secondes;
  }
  start() {
    this._timer = setInterval(() => {
      this._minutes = Math.floor(++this._totalSecondes / 60);
      this._secondes = this._totalSecondes - this._minutes * 60;
    }, 1000);
  }
  stop() {
    clearInterval(this._timer);
  }
  reset() {
    this._totalSecondes = this._minutes = this._secondes = 0;
  }
}
