<div class="background1 p-lr-0-mobile pl-3 pt-3 pr-3 pb-0 float-left w-100 pos-rel h-100">
    <div class="container-fluid">
        <header class="pt-4 pl-4 p-lr-0-mobile pr-4">
            <a class="navbar-brand" routerLink="/accueil"><img style="width: auto; height: 80px" src="../../../assets/logo-safesante-blanc.png" alt="logo safesante blanc" /></a>
            <a class="nav-link color-white font16 bold float-right mt-1-mobile" href="https://www.safesante.fr/search">Découvrez MaTeleconsult</a>
        </header>
    </div>
    <div class="content-wrap">
        <div class="container mobilepad0">
            <div class="col-sm-12 w-100 mt-7r text-center mobilepad0">
                <div class="mainheading mb-5 color-white">
                        Connectez-vous
                </div>
                <div class="col-sm-10 col-lg-8 d-inline-block mobilepad0">
                    <form class="backgroundwhite pt-5 pl-5 pr-5 pb-2 shadow mp10 mb-4">
                        <div class="w-100 form-container">
                            <form #form="ngForm" (submit)="signIn(form)">
                                <div class="row mb-3">
                                    <div class="col-sm-12">
                                        <div class="form-group sc">
                                            <input type="email" class="form-control font18" id="usr-email" placeholder="Identifiant" [(ngModel)]="loginModel.email" name="email" #email="ngModel" [ngClass]="{'required' : email.invalid && form.submitted}" required>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mb-3">
                                    <div class="col-sm-12">
                                        <div class="form-group sc">
                                            <input type="password" class="form-control font18" id="usr-password" placeholder="Mot de passe" [(ngModel)]="loginModel.password" name="password" #password="ngModel" [ngClass]="{'required' : password.invalid && form.submitted}" required>
                                            <div class="text-right mt-2">
                                                <a routerLink="/resetPassword" class="purple">Mot de passe oublié</a>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div *ngIf="error" class="error">Identifiant ou mot de passe erroné.</div>
                                <div class="row mb-3">
                                    <div class="col-sm-12">
                                        <div class="form-group sc">
                                            <button type="submit" class="green-btn form-control color-white shadow purplebackground cp font16 bold text-uppercase">connexion</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>

</div>