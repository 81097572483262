<div class="h-100 w-100 blue-curve-background">
  <div class="text-center pb-2 pt-1">
    <img
      class="mx-auto"
      style="width: auto; height: 80px"
      src="../../../assets/logo-safesante-blanc.png"
    />
  </div>
  <div
    class="backgroundwhite pos-rel pt-3 pb-3 mb-3 h-auto float-left w-100 h-100"
    style="text-align: center"
  >
    <div class="content-wrap">
      <div class="container pt-2 pb-2">
        <div class="row">
          <div *ngIf="consultation" class="col-sm-6 mb-3 text-left">
            <div class="font46 body-text bold">
              Vous êtes dans<br />
              notre salle d’attente virtuelle<br />
            </div>
            <div class="font28 color-blue mb-3 mt-3">
              <br />
              Nous vous invitons à patienter,<br />
              votre téléconsultation va démarrer automatiquement dans quelques
              minutes, dès qu'un médecin sera disponible.
            </div>
          </div>
          <div class="col-sm-6 imgs">
            <div class="row mb-3">
              <ngb-carousel
                id="carouselExampleSlidesOnly"
                style="height: 300px"
                #carouselExampleSlidesOnly="ngbCarousel"
                class="carousel slide mx-auto"
                data-ride="carousel"
              >
                <ng-template ngbSlide>
                  <img
                    class="d-block w-100"
                    src="../../../assets/Safesante-salle-attente1.png"
                    alt="Safesante salle attente"
                  />
                </ng-template>
                <ng-template ngbSlide>
                  <img
                    class="d-block w-100"
                    src="../../../assets/Safesante-salle-attente2.png"
                    alt="Safesante salle attente"
                  />
                </ng-template>
                <ng-template ngbSlide>
                  <img
                    class="d-block w-100"
                    src="../../../assets/Safesante-salle-attente3.png"
                    alt="Safesante salle attente"
                  />
                </ng-template>
                <ng-template ngbSlide>
                  <img
                    class="d-block w-100"
                    src="../../../assets/Safesante-salle-attente4.png"
                    alt="Safesante salle attente"
                  />
                </ng-template>
              </ngb-carousel>
            </div>
            <div *ngIf="carousel" class="progress mx-auto" style="width: 280px">
              <div
                class="progress-bar"
                [ngStyle]="{ width: getProgressBarWidth() }"
              ></div>
            </div>
            <div class="font28 color-light-blue mb-2 mt-3">Temps attendu:</div>
            <safesante-matlc-clock></safesante-matlc-clock>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
