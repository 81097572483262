import { Component, OnInit, Renderer2 } from '@angular/core';
import { Params, ActivatedRoute, Router } from '@angular/router';
import { PatientService } from '../patient.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserModel } from '../../model/safesante-ysera';

@Component({
  selector: 'safesante-matlc-check-payment',
  templateUrl: './check-payment.component.html',
  styleUrls: ['./check-payment.component.scss'],
})
export class CheckPaymentComponent implements OnInit {
  cancel: any;

  constructor(
    private renderer: Renderer2,
    private activatedRoute: ActivatedRoute,
    private patientService: PatientService,
    private router: Router,
    private deviceDector: DeviceDetectorService,
    private appointmentDelayModal: NgbModal
  ) {}

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      const sessionId = params['session_id'];
      const consultationId = params['consultation_id'];
      console.log('sessionId: ' + sessionId);
      this.patientService.checkPayment(sessionId, consultationId).subscribe(
        (done: boolean) => {
          if (done) {
            this.patientService
              .getConnectedUser()
              .subscribe((connectedUser: UserModel) => {
                console.log('User is authenticated');
                sessionStorage.setItem(
                  'sessionUser',
                  JSON.stringify(connectedUser)
                );
                this.patientService
                  .getConsultationSV(consultationId)
                  .subscribe((consultation) => {
                    this.patientService.setConsultation(consultation);
                    this.router.navigate(['/waiting-room']);
                  });
              });
          }
        },
        () => {
          this.patientService
            .getConnectedUser()
            .subscribe((connectedUser: UserModel) => {
              console.log('User is authenticated');
              this.patientService
                .getConsultationSV(consultationId)
                .subscribe(() => {
                  sessionStorage.setItem(
                    'sessionUser',
                    JSON.stringify(connectedUser)
                  );
                  this.router.navigate(['/accueil']);
                });
            });
        }
      );
    });
  }
}
