import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LOCAL_STORAGE } from '@safesante/utils';
import { Observable, Subject } from 'rxjs';
import {
  LoginModel, PairingDetails, StationResponse
} from '../model/safesante-ysera';
import {
  ConsultationModel, HealthRecordModel, PasswordResetModel, PocRegisterModel, TytoPocModel, WifiAuthInfoModel
} from './../model/safesante-ysera';

@Injectable({
  providedIn: 'root',
})
export class PocService {
  logoutSubject: Subject<any> = new Subject<any>();

  userConnected: Subject<any> = new Subject<any>();

  constructor(
    private http: HttpClient,
    @Inject(LOCAL_STORAGE) private localStorage: Storage,
    private router: Router
  ) {}

  getId() {
    const id = sessionStorage.getItem('sessionUser')
      ? parseInt(JSON.parse(sessionStorage.getItem('sessionUser')).id)
      : null;
    return sessionStorage.getItem('sessionUser') ? id : null;
  }

  setLocalPocProfile(pocProfile: TytoPocModel) {
    this.localStorage.setItem('pocProfile', JSON.stringify(pocProfile));
  }

  getLocalPocProfile() {
    const pocProfile = this.localStorage.getItem('pocProfile');
    return pocProfile != null ? JSON.parse(pocProfile) : null;
  }

  getPocId() {
    const pocProfile = this.localStorage.getItem('pocProfile');
    return pocProfile ? JSON.parse(pocProfile).id : null;
  }

  getDeviceStatus() {
    return this.http.get<StationResponse>(
      '/api/devicePoc/' + this.getPocId() + '/status'
    );
  }

  getPairingDetails() {
    return this.http.get<PairingDetails>(
      '/api/devicePoc/' + this.getPocId() + '/pairingDetails'
    );
  }

  getPrescriptionFile(prescriptionId: number, consultationId: number) {
    return this.http.get(
      '/api/devicePoc/' +
        this.getId() +
        '/' +
        consultationId +
        '/prescriptions/' +
        prescriptionId +
        '/file',
      { responseType: 'arraybuffer', observe: 'response' }
    );
  }

  getReportFile(consultationId: number, reportId: number) {
    return this.http.get(
      '/api/devicePoc/' +
        this.getId() +
        '/' +
        consultationId +
        '/reports/' +
        reportId +
        '/file',
      { responseType: 'arraybuffer', observe: 'response' }
    );
  }

  getHealthRecords(): Observable<HealthRecordModel[]> {
    console.log('getting health record...');
    return this.http.get<HealthRecordModel[]>(
      '/api/devicePoc/' + this.getId() + '/healthrecords'
    );
  }

  resumeLastTLC() {
    return this.http.get<ConsultationModel>(
      '/api/devicePoc/' + this.getId() + '/resumeLastConsultation'
    );
  }

  getLastTLC() {
    return this.http.get<ConsultationModel>(
      '/api/devicePoc/' + this.getId() + '/lastConsultation'
    );
  }

  postNewPassword(passwordResetModel: PasswordResetModel) {
    return this.http.post(
      '/api/authentication/resetpassword',
      passwordResetModel
    );
  }

  login(loginForm: LoginModel) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/x-www-form-urlencoded; charset=UTF-8'
    );
    let credentials =
      'username=' + loginForm.email + '&password=' + loginForm.password;
    if (loginForm.rememberMe) credentials += 'remember-me=on';
    console.log('login started info: /api/login');
    return this.http.post('/api/login', credentials, {
      headers: headers,
      responseType: 'text',
    });
  }

  logoutCall() {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/x-www-form-urlencoded; charset=UTF-8'
    );
    return this.http.get('/api/logout', {
      headers: headers,
      responseType: 'text',
    });
  }

  resetPassword(email: string) {
    return this.http.post('/api/authentication/resetPassword', email);
  }

  logout() {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/x-www-form-urlencoded; charset=UTF-8'
    );
    this.http
      .get('/api/logout', { headers: headers, responseType: 'text' })
      .subscribe(
        () => {
          sessionStorage.clear();
          this.router.navigate(['/accueil']);
        },
        () => {
          sessionStorage.clear();
          this.router.navigate(['/accueil']);
        }
      );
    this.logoutSubject.next(null);
  }

  getEmailForPasswordReset(resetToken: string) {
    return this.http.get(
      '/api/authentication/tokenUser?resetToken=' + resetToken,
      { responseType: 'text' }
    );
  }

  getPocProfile() {
    return this.http.get<TytoPocModel>('/api/devicePoc/' + this.getId());
  }

  getConnectedUser() {
    return this.http.get('/api/authentication');
  }

  getEmailForAccountValidation(token: string) {
    return this.http.get(
      '/api/authentication/registerPocEmail?token=' + token,
      { responseType: 'text' }
    );
  }
  validatePocAccount(pocRegisterModel: PocRegisterModel) {
    return this.http.post('/api/authentication/registerPoc', pocRegisterModel);
  }

  getWifiAuthInformation() {
    return this.http.get<WifiAuthInfoModel>(
      '/api/devicePoc/' + this.getId() + '/wifiAuth'
    );
  }

  postWifiAuthInformation(wifiAuthInfoModel: WifiAuthInfoModel) {
    const pocId = this.getId() ? this.getId() : this.getPocId();
    return this.http.post(
      '/api/devicePoc/' + pocId + '/wifiAuth',
      wifiAuthInfoModel
    );
  }
}
