<div class="row">
  <div class="col">
    <div class="float-right">
      <span
        class="close"
        data-dismiss="modal"
        aria-label="Close"
        (click)="close()"
      >
        <span aria-hidden="true"
          ><i class="fas fa-times-circle pointer"></i
        ></span>
      </span>
    </div>
  </div>
</div>
<safesante-file-preview [data]="data" (closeEvent)="close()"></safesante-file-preview>
