import { animate, style, transition, trigger } from '@angular/animations';
import { DOCUMENT } from '@angular/common';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import {
  AfterContentInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Observable } from 'rxjs';

export interface IDocumentModalData {
  file?: Blob;
  documentId?: number;
  contentType?: string;
  documentType?: string;
  isEmbeded?: boolean;
  title?: string;
  request?: Observable<HttpEvent<never>>;
}

@Component({
  selector: 'safesante-base-file-preview',
  templateUrl: './base-file-preview.component.html',
  styleUrls: ['./base-file-preview.component.scss'],
  animations: [
    trigger('mobileBanner', [
      transition(':enter', [
        style({ top: -60 }),
        animate('.3s ease', style({ top: 0 })),
      ]),
      transition(':leave', [
        style({ top: 0 }),
        animate('.3s ease', style({ top: -60 })),
      ]),
    ]),
  ],
})
export class BaseFilePreviewComponent implements OnInit, AfterContentInit {
  @Input() data: IDocumentModalData;

  @ViewChild('imageElement') private imageElR: ElementRef;
  downloadProgress = 0;

  url: SafeResourceUrl;
  isMobile = false;
  pdfType = 'application/pdf';
  pdfSource: string | ArrayBuffer | Blob | Uint8Array | { range: any; };

  contentType: string;

  showPdfReader = true;
  currentPdfPage: number;

  currentPdfZoom: number;
  @Output() mouseMove = new EventEmitter();

  @Output() makeCoordinatesZero = new EventEmitter();


  @Output()
  pdfClickEvent: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();

  @Output()
  imgClickEvent:  EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();

  get imageEl(): HTMLImageElement {
    if (this.imageElR) {
      return this.imageElR.nativeElement;
    }
    return null;
  }

  constructor(
    @Inject(DOCUMENT) private document: Document,
    public sanitizer: DomSanitizer,
    private deviceService: DeviceDetectorService
  ) { }

  ngAfterContentInit() {
    let percentDone = 0;

    this.data.request.subscribe({
      next: (response) => {
        if (response.type === HttpEventType.DownloadProgress) {
          // This is a download progress event. Compute and show the % done:
          percentDone = Math.round((100 * response.loaded) / response.total);
          this.downloadProgress = percentDone;
        } else if (response.type === HttpEventType.Response) {
          this.contentType = response.headers.get('content-type');
          this.data.file = new Blob([response.body], {
            type: this.contentType,
          });
          this.url = this.sanitizer.bypassSecurityTrustResourceUrl(
            URL.createObjectURL(this.data.file)
          );
          if (this.contentType.includes(this.pdfType)) {
            this.pdfSource = this.data.file;
          }
        }
      },
      error: (error) => {
        console.error(error);
      }
    });
  }

  ngOnInit() {
    this.isMobile = this.deviceService.isMobile();
  }

  printDocument() {
    const input: HTMLInputElement = <HTMLInputElement>(
      this.document.getElementById('print')
    );
    if (input) {
      input.click();
    }
  }

  download() {
    const a = this.document.createElement('a');
    a.setAttribute('href', URL.createObjectURL(this.data.file));
    a.setAttribute('target', '_blank');

    const fileName = this.getFileName(this.data.title);

    a.setAttribute('download', fileName);
    // start download
    a.click();
  }



  pdfClick($event: MouseEvent): void {
    console.log('pdfClick');
    this.pdfClickEvent.emit($event);
  }

  imgClick($event: MouseEvent): void {
    console.log('imgClick');

    this.imgClickEvent.emit($event);
  }

  setZoom($event: number) {
    this.currentPdfZoom = $event;
  }


  private getFileName(title: string) {
    if (title) return this.data.title.replace(/['"]+/g, '');
    return this.data.contentType == this.pdfType ? 'compte-rendu' : 'document';
  }

  @HostListener('window:resize', ['$event']) onResize() {
    this.isMobile = this.deviceService.isMobile();
  }
}
