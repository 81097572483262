<div class="container p-8">            
        <div class="row justify-content-end"> 
                <div class="float-right mb-2">    
                        <span class="close" data-dismiss="modal" aria-label="Close" (click)="dismissModal()">
                                <span aria-hidden="true"><i class="fas fa-times-circle pointer"></i></span>
                        </span>
                </div>
        </div>
        <div class="row py-5" id="delay">
            <div class="col-12 px-2 text-padding" id="text">
                <p class="text-black bg-white p-1">
                {{practitionerName}} vous prévient d'un retard de {{delay}} minutes 
               
                </p>
                
            </div>
        </div>
</div>
