<div class="file-preview file-preview-wrapper">
  <div *ngIf="!pdfSource && !url">
    <h5>Veuillez attendre le chargement du document...</h5>
    <hr />
    <ngb-progressbar [showValue]="true" type="info" [value]="downloadProgress"></ngb-progressbar>
  </div>
  <div *ngIf="contentType?.includes(pdfType)" id="my-container" #pdfContainer class="ng-scope pdfobject-container mt-2"
  (mousemove)="mouseMove.emit($event)" (mouseleave)="makeCoordinatesZero.emit()">
    <ngx-extended-pdf-viewer *ngIf="showPdfReader" [src]="pdfSource" [useBrowserLocale]="true" type="application/pdf"
      width="100%" (click)="pdfClick($event)" [(page)]="currentPdfPage" (currentZoomFactor)="setZoom($event)"
      style="overflow: auto">
    </ngx-extended-pdf-viewer>
  </div>
  <div *ngIf="url && !contentType?.includes(pdfType)" class="pdfobject-container">
    <div class="container" #imgContainer (mousemove)="mouseMove.emit($event)"
      (mouseleave)="makeCoordinatesZero.emit()">
      <img [src]="url" class="img-border" alt="attached image" (click)="imgClick($event)" #imageElement />
    </div>
  </div>
</div>
<div class="row ml-0 mr-0 mb-3">

  <ng-content></ng-content>
</div>
