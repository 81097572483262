import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConsultationModel, UserModel } from '../../model/safesante-ysera';
import { PatientService } from './../patient.service';
import { ReportComponent } from './../report/report.component';

@Component({
  selector: 'safesante-matlc-waiting-report',
  templateUrl: './waiting-report.component.html',
  styleUrls: ['./waiting-report.component.scss'],
})
export class WaitingReportComponent implements OnInit, OnDestroy {
  consultation: ConsultationModel;
  interval: any;

  constructor(
    private patientService: PatientService,
    private ngbModal: NgbModal,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      const code = params['consultationToken'];

      if (code && code != '') {
        this.patientService
          .getLinkedFreeConsultation(code)
          .subscribe((consultation: ConsultationModel) => {
            this.consultation = consultation;
            this.patientService.getConnectedUser().subscribe(
              (connectedUser: UserModel) => {
                sessionStorage.setItem(
                  'sessionUser',
                  JSON.stringify(connectedUser)
                );
                this.openReport();
              },
              (error) => {
                console.log(error);
              }
            );
          });
      } else {
        this.checkReportReady();
      }
    });
  }

  private checkReportReady() {
    this.consultation = this.patientService.getConsultation();
    this.patientService
      .getConsultationSV(this.consultation.id)
      .subscribe((consultation) => {
        this.consultation = consultation;
        this.interval = setInterval(() => {
          this.patientService
            .isReportReady(this.consultation.id)
            .subscribe((isReportReady: boolean) => {
              if (isReportReady) {
                clearInterval(this.interval);
                this.openReport();
              }
            });
        }, 1000);
      });
  }

  private openReport() {
    const modalRef = this.ngbModal.open(ReportComponent);
    modalRef.componentInstance.consultation = this.consultation;
    modalRef.result.then(
      () => {
        this.patientService.logout();
      },
      () => {
        this.patientService.logout();
      }
    );
  }

  ngOnDestroy() {
    clearInterval(this.interval);
  }
}
