import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { LOCAL_STORAGE, SESSION_STORAGE } from '@safesante/utils';
import { Observable } from 'rxjs';

export interface ConsultationModel {
  id: number;
  customerId: number;
  customerName: string;
  room: string;
  status: ConsultationStatus;
}

export enum ConsultationStatus {
  COMING = 'COMING',
  PATIENT_JOINING = 'PATIENT_JOINING',
  PATIENT_JOINED = 'PATIENT_JOINED',
  PRACTITIONER_JOINED = 'PRACTITIONER_JOINED',
  ONGOING = 'ONGOING',
  PATIENT_ONGOING = 'PATIENT_ONGOING',
  PATIENT_LEFT = 'PATIENT_LEFT',
  PRACTITIONER_LEFT = 'PRACTITIONER_LEFT',
  DONE = 'DONE',
  CANCELLED = 'CANCELLED',
  IRL = 'IRL',
}

@Injectable({
  providedIn: 'root'
})
export class ConsultationService {


  constructor(private http: HttpClient,
    @Inject(SESSION_STORAGE) private sessionStorage: Storage,
    @Inject(LOCAL_STORAGE) private localStorage: Storage) { }

  getId(): number {
    const sessionUserJson = this.sessionStorage.getItem('sessionUser');
    return sessionUserJson ?  JSON.parse(sessionUserJson).id : null;
  }

  setConsultation(consultation: ConsultationModel) {
    this.localStorage.setItem('consultation', JSON.stringify(consultation));
  }



  getConsultation(): ConsultationModel {
    const consultationJSON = this.localStorage.getItem('consultation');
    const consultation: ConsultationModel = consultationJSON? JSON.parse(consultationJSON): null;
    return consultation;
  }

  setConsultationId(consultationId: number) {
    this.localStorage.setItem('consultationId', JSON.stringify(consultationId));
  }


  getConsultationId() : number{
    const consultationJSON = this.localStorage.getItem('consultationId');
    const consultationId: number = consultationJSON? JSON.parse(consultationJSON): null;
    return consultationId;
  }

  isConsultationEnded(idConsultation: number): Observable<boolean> {
    return this.http.get<boolean>(
      '/api/v2/patients/' +
      this.getId() +
      '/' +
      this.getId() +
      '/consultations/isEnded/' +
      idConsultation
    );
  }

  patchConsultationStatus(
    consultationId: number,
    status: ConsultationStatus,
    beneficiaryId: number
  ): Observable<ConsultationModel> {
    return this.http.patch<ConsultationModel>(
      '/api/v2/patients/' +
      this.getId() +
      '/' +
      // Should be replaced by beneficiary id after the backend is ready
      this.getId() +
      '/consultations/' +
      consultationId +
      '/status',
      status
    );
  }
}
