import { PairingDeviceComponent } from './pairing-device/pairing-device.component';
import { PocRoutingModule } from './poc-routing.module';

import { PairingInfoComponent } from './pairing-info/pairing-info.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { QRCodeModule } from 'angularx-qrcode';
import { LoginComponent } from './login/login.component';
import { RegisterPocComponent } from './register-poc/register-poc.component';
import { RegistrationConfirmationComponent } from './registration-confirmation/registration-confirmation.component';
import { ReportsComponent } from './reports/reports.component';
import { FilePreviewComponent } from '../patient/file-preview/file-preview.component';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        BrowserModule,
        HttpClientModule,
        PocRoutingModule,
        FormsModule,
        NgbModule,
        QRCodeModule,
    ],
    declarations: [
        PairingDeviceComponent,
        PairingInfoComponent,
        LoginComponent,
        RegisterPocComponent,
        RegistrationConfirmationComponent,
        ReportsComponent,
    ],
    exports: [RouterModule]
})
export class PocModule {}
