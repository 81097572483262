let icons: any = {
	alert: '',
	error: '',
	info: '',
	success: "<i class='fa fa-check' aria-hidden='true'></i>",
	warn: ""
};

export class NotificationConstants {
	/* USER*/
	public static get PASSWORD_CHANGED_MESSAGE_SUCCESS(): string { return "Le nouveau a bien été enregistré."; };

	/* ADMIN */
	public static get PRACTITIONER_SAVED_MESSAGE_SUCCESS(): string { return "Le médecin a bien été enregistré."; };
	public static get PRACTITIONER_DELETED_MESSAGE_SUCCESS(): string { return "Le médecin a bien été supprimé."; };
	public static get PRACTITIONER_UPDATE_MESSAGE_SUCCESS(): string { return "Le médecin a bien été mis à jour."; };

	public static get POC_SAVED_MESSAGE_SUCCESS(): string { return "La pharmacie a bien été enregistrée."; };
	public static get POC_DELETED_MESSAGE_SUCCESS(): string { return "La pharmacie a bien été supprimée."; };
	public static get POC_UPDATE_MESSAGE_SUCCESS(): string { return "La pharmacie a bien été mise à jour."; };


	public static get PATIENT_SAVED_MESSAGE_SUCCESS(): string { return "Le patient a bien été enregistré."; };
	public static get PATIENT_DELETED_MESSAGE_SUCCESS(): string { return "Le patient a bien été supprimé."; };
	public static get PATIENT_UPDATE_MESSAGE_SUCCESS(): string { return "Le patient a bien été mis à jour."; };

	/* PATIENT */

	public static get PATIENT_ADD_SUCCESS(): string { return "Le patient a bien été ajouté."; };
	public static get QUESTION_SENT_MESSAGE_SUCCESS(): string { return "Votre question a bien été envoyée."; };
	public static get ALLERGY_SENT_MESSAGE_SUCCESS(): string { return "Votre allergie a bien été enregistrée."; };
	public static get HISTORY_SENT_MESSAGE_SUCCESS(): string { return "Votre antécédent a bien été enregistré."; };
	public static get PATHOLOGY_SENT_MESSAGE_SUCCESS(): string { return "Votre pathologie a bien été enregistrée."; };
	public static get TREATMENT_SENT_MESSAGE_SUCCESS(): string { return "Le traitement a bien été enregistrée."; };
	public static get PRESCRIPTION_SENT_MESSAGE_SUCCESS(): string { return "Votre ordonnance a bien été enregistrée."; };
	public static get DOCUMENT_SENT_MESSAGE_SUCCESS(): string { 	return "Votre document a bien été enregistré."; };

	public static get REPORT_SENT_MESSAGE_SUCCESS(): string { return "Votre compte-rendu a bien été enregistrée."; };
	public static get HEALTHRECORD_SENT_MESSAGE_SUCCESS(): string { return "Le carnet de santé a bien été mis à jour." };
	public static get SURVEY_SENT_MESSAGE_SUCCESS(): string { return "L'enquête a bien été enregistrée. Vous allez être redirigé." };
	public static get PROFILE_SENT_MESSAGE_SUCCESS(): string { return "Votre profil a bien été mis à jour." };
	public static get PATIENT_CHARGED_MESSAGE_SUCCESS(): string { return "Votre patient a bien été facturé"; };


	public static get FAMILY_CREATED_MESSAGE_SUCCESS(): string { return "Le membre de la famille a correctement été ajouté" }
	public static get FAMILY_UPDATED_MESSAGE_SUCCESS(): string { return "Le membre de la famille a correctement été mis à jour" }
	public static get FAMILY_DELETED_MESSAGE_SUCCESS(): string { return "Le membre de la famille a correctement été supprimé" }
	public static get GENERAL_PRACTITIONER_UPDATED_MESSAGE_SUCCESS(): string { return "Le membre de la famille a correctement été supprimé" }
	public static get UPDATE_PROFILE_MESSAGE_SUCCESS(): string { return "Le membre de la famille a correctement été supprimé" }
	public static get GENERAL_PRACTITIONER_UPDATED_MESSAGE(): string { return "Ce médecin a bien été modifié" }

	
	public static get APPOINTMENT_SAVED_MESSAGE_SUCCESS(): string { return "Le rendez vous a correctement été pris en compte"}
	public static get APPOINTMENT_DELETE_MESSAGE_SUCCESS(): string { return "Le rendez vous a correctement été supprimé"}
	public static get APPOINTMENT_PATCH_MESSAGE_SUCCESS(): string { return "Le rendez vous a correctement été modifié"}
	public static get DOCUMENT_DELETE_MESSAGE_SUCCESS(): string { return " Le document à été correctement supprimé"}
	public static get DOCUMENT_SAVED_MESSAGE_SUCCESS(): string { return " Le document à été télécharger"}


	/* PRACTITIONER */
	public static get UPDATE_PRACTITIONER_PROFILE_MESSAGE_SUCCESS(): string {return "Votre profil a bien été modifié."}
	public static get POST_MOTIVE_MESSAGE_SUCCESS(): string {return "Le motif a bien été ajouté."}
	public static get UPDATE_MOTIVE_MESSAGE_SUCCESS(): string {return "Le motif a bien été modifié."}
	public static get DELETE_MOTIVE_MESSAGE_SUCCESS(): string {return "Le motif a bien été supprimé."}
	public static get ADD_MANAGER_MESSAGE_SUCCESS(): string {return "Le manager a bien été ajouté."}
	public static get UPDATE_MANAGER_MESSAGE_SUCCESS(): string {return "Le manager a bien été modifié."}
	public static get ADD_WORKPLACE_MESSAGE_SUCCESS(): string {return "Un nouveau lieu de travail a bien été ajouté."}
	public static get DELETE_WORKPLACE_MESSAGE_SUCCESS(): string {return "Le nouveau lieu de travail a bien été supprimé."}
	public static get UPDATE_WORKPLACE_MESSAGE_SUCCESS(): string {return "Un nouveau lieu de travail a bien été modifié."}
	public static get UPDATE_WORKING_DAYS_MESSAGE_SUCCESS(): string {return "Vos modifications ont bien été mises à jour."}
	public static get SAVE_APPOINTMENT_MESSAGE_SUCCESS(): string {return "Le rendez-vous a bien été ajouté."}
	public static get UPDATE_APPOINTMENT_MESSAGE_SUCCESS(): string {return "Le rendez-vous a bien été modifié."}
	public static get DELETE_APPOINTMENT_MESSAGE_SUCCESS(): string {return "Le rendez-vous a bien été supprimé."}
	public static get CONSULTATION_ENDED_MESSAGE_SUCCESS() : string { return "La consultation est terminée. Vous allez être redirigé."}
	public static get ADD_CUSTOMER_MESSAGE_SUCCESS() : string { return "Le nouveau patient a bien été ajouté."}
	public static get DELAY_PATIENT_MESSAGE_SUCCESS() : string { return "Votre retard a bien été signalé au patient."}


	public static get SIGNATURE_VALIDATED_MESSAGE_SUCCESS() : string { return "Votre signature a été enregistrée."}

	/* PRESCRPTION */
	public static get PRESCRIPTION_TREATMENT_ADDED_MESSAGE_SUCCESS(): string { return "Le traitement a bien été ajouté"; };
	public static get PRESCRIPTION_TREATMENT_UPDATED_MESSAGE_SUCCESS(): string { return "Le traitement a bien été modifié"; };
	public static get PRESCRIPTION_TREATMENT_DELETED_MESSAGE_SUCCESS(): string { return "Le traitement a bien été supprimé"; };
	public static get PRESCRIPTION_TREATMENT_RENEWED_ADDED_MESSAGE_SUCCESS(): string { return "Le traitement à renouveler a bien été ajouté"; };
	public static get PRESCRIPTION_TREATMENT_CONVERTED_MESSAGE_SUCCESS(): string { return "Le traitement a bien été converti en DC"; };
	public static get PRESCRIPTION_TREATMENT_CONVERTION_FAILED_MESSAGE_SUCCESS(): string { return "Le traitement ne peut pas être converti en DC"; };

	/*Hotliner*/

	public static get UPDATE_PATIENT_MESSAGE_SUCCESS(): string { return "Ce patient a bien été modifié." }


	public static get ALLERGY_DELETE_MESSAGE_SUCCESS(): string { return "Votre allergie a bien été supprimée."; };
	public static get ALLERGEN_MOLECULE_DELETE_MESSAGE_SUCCESS(): string { return "La molécule allergène a bien été supprimée."; };
	public static get HISTORY_DELETE_MESSAGE_SUCCESS(): string { return "Votre antécédent a bien été supprimé."; };
	public static get PATHOLOGY_DELETE_MESSAGE_SUCCESS(): string { return "Votre pathologie a bien été supprimée."; };
	public static get TREATMENT_CANCELED_MESSAGE_SUCCESS(): string { return "Le traitement a bien été arrêté."; };
	public static get TREATMENT_DELETE_MESSAGE_SUCCESS(): string { return "Le traitement a bien été supprimé."; };
	public static get PRESCRIPTION_DELETE_MESSAGE_SUCCESS(): string { return "Votre prescription a bien été annulé."; };

	public static get TRANSFER_MADE_MESSAGE_SUCCESS(): string { return "La question a bien été transférée à une autre spécialité."; };
	public static get PASSWORD_UPDATED_MESSAGE_SUCCESS(): string { return "Le mot de passe a été mis a jour"}

	/* ERRORS */
	public static get MESSAGE_ERROR(): string { return "Une erreur s'est produite."; };
	public static get APPOINTMENT_TAKEN_MESSAGE_ERROR(): string { return "Ce rendez-vous est déjà pris. Veuillez en choisir un autre."; };

	public static get APPOINTMENT_ALREADY_EX_MESSAGE_ERROR(): string { return "Un rendez-vous est déjà prévu aux heures sélectionnées."; };


	public static get PATIENT_CHARGED_MESSAGE_ERROR(): string { return "Une erreur est survenue pendant la facturation de votre patient"; };

	public static get DELAY_PATIENT_MESSAGE_ERROR(): string { return "Une erreur est survenue pendant le signalement de retard à votre patient"; };


	public static get PROPERTIES(): any {
		return {
			timeOut: 3000,
			showProgressBar: false,
			pauseOnHover: false,
			clickToClose: true,
			rtl: false,
			animate: 'fromBottom',
			icons: icons,
		};
	};
}
