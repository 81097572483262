import { isPlatformBrowser } from '@angular/common';
import {
  Component,
  Inject,
  InjectionToken,
  Input,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CancelComponent, ICancelModalData } from '@safesante/shared';
import { filter, Subject, take } from 'rxjs';

import { ConsultationModel, ConsultationService, ConsultationStatus } from './consultation.service';




class VideoProps {
  constructor(
    public meeting: string,
    public height: string,
    public endCall: boolean
  ) { }
}

@Component({
  selector: 'safesante-patient-consultation',
  templateUrl: './consultation.component.html',
  styleUrls: ['./consultation.component.scss'],
})
export class ConsultationComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject();

  consultation!: ConsultationModel;

  mobileOrTablet = false;
  isPortrait = false;

  videoProps!: VideoProps;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  consultationCheckerInterval!:string | number  | any |undefined ;

  isBrowser: boolean;
  @Input() logoPath = '';

  constructor(
    private dialog: MatDialog,
    private router: Router,
    private consultationService: ConsultationService,
    @Inject(PLATFORM_ID) private platformId: InjectionToken<unknown>) {
    this.isBrowser = isPlatformBrowser(this.platformId);
  }

  ngOnInit(): void {
    const consultationId = this.consultationService.getConsultationId();
    this.consultationService
      .patchConsultationStatus(
        consultationId,
        ConsultationStatus.PATIENT_ONGOING,
        //TODO : set up beneficiary instead of 0
        0
      )
      .subscribe((consultation) => {
        this.consultation = consultation;
        this.videoProps = new VideoProps(this.consultation.room, '86vh', false);
        this.consultationService.setConsultation(this.consultation);
      });

    this.initConsultationEndedChecker();
  }

  openCancelConsultationModal() {
    const dialogRef = this.dialog.open<any, ICancelModalData>(CancelComponent, {
      data: {
        title: 'vous êtes sur le point de terminer la consultation',
        message: 'Êtes-vous certain de vouloir finir cette consultation?',
        confirmButton: 'terminer la consultation',
        returnButton: 'revenir à la consultation',
      },
    });

    dialogRef
      .afterClosed()
      .pipe(
        take(1),
        filter((r) => !!r)
      )
      .subscribe(this.endConsultation.bind(this));
  }

  endConsultation() {
    this.consultation.status = ConsultationStatus.PATIENT_LEFT;
    this.consultationService.setConsultation(this.consultation);
    this.consultationService
      .patchConsultationStatus(
        this.consultation.id,
        ConsultationStatus.PATIENT_LEFT,
        this.consultation.customerId
      )
      .subscribe(() => {
        this.videoProps = new VideoProps(this.consultation.room, '86vh', true);
        setTimeout(() => {
          this.router.navigate([
            '/patient/compte-rendu/' + this.consultation.customerId,
          ]);
        }, 500);
      });
  }

  private initConsultationEndedChecker() {
    this.consultationCheckerInterval = setInterval(() => {
      this.consultationService
        .isConsultationEnded(this.consultation.id)
        .subscribe((isConsultationEnded: boolean) => {
          if (isConsultationEnded) {
            clearInterval(this.consultationCheckerInterval);
            this.endConsultation();
          }
        });
    }, 3000);
  }
  ngOnDestroy() {
    clearInterval(this.consultationCheckerInterval);
    this.videoProps = new VideoProps(this.consultation.room, '86vh', true);

    this.destroy$?.next(null);
    this.destroy$?.complete();
  }
}
