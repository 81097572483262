<ng-container *ngIf="consultation">
  <div class="h-100" style="overflow-x: hidden; overflow-y: hidden">
    <div class="flex-row d-flex vh-100">
      <div
        class="overflow-hidden p-0"
        [class.video-component-height-mobile]="isPortrait"
        [class.col-12]="isPortrait"
        [class.col-8]="!isPortrait"
      >
        <div class="h-100">
          <div id="video" class="h-100">
            <div class="blue-container w-100 m-0">
              <div
                class="blue-button-margin p-2 h-100 w-100 flex-row d-flex m-0"
              >
                <div
                  class="col-md-4 col-lg-4 col-6 d-sm-none d-xs-none d-md-none d-none d-lg-block d-xl-block"
                ></div>
                <div
                  class="col-md-6 col-sm-6 col-xs-6 col-lg-4 col-4 align-self-center"
                >
                  <img
                    class="d-none d-lg-block d-xl-block center-block mx-auto d-block"
                    style="width: auto"
                    [src]="logoPath"
                    alt="logo safesante blanc"
                  />
                </div>
                <div class="col-md-6 col-sm-6 col-xs-6 col-lg-4 col-4">
                  <button
                    *ngIf="!mobileOrTablet"
                    type="button"
                    class="material-icons blue-icon-patient btn red fill text-uppercase p-3 mr-5 float-right zero-margin-mobile"
                    (click)="openCancelConsultationModal()"
                  >
                    terminer la téléconsultation
                  </button>
                  <button
                    *ngIf="mobileOrTablet"
                    type="button"
                    class="material-icons blue-icon-patient btn red fill text-uppercase p-3 mr-5 float-right zero-margin-mobile"
                    (click)="openCancelConsultationModal()"
                  >
                    terminer
                  </button>
                </div>
              </div>
            </div>
            <div class="m-0" *ngIf="videoProps">
              <safesante-visio [props]="videoProps | json"></safesante-visio>
            </div>
          </div>
        </div>
      </div>
      <div
        class="zero-margin-mobile"
        [class.p-0]="!mobileOrTablet"
        [class.chat-height-mobile]="isPortrait"
        [class.col-12]="isPortrait"
        [class.col-4]="!isPortrait"
      >
        <div class="h-100 white-background">
          <div class="h-100">
            <safesante-chat [consultationId]="consultation.id"></safesante-chat>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
