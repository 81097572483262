import { Component, OnInit, ViewEncapsulation } from '@angular/core';

import * as flipclock from 'flipclock';

declare var $: any;

@Component({
  selector: 'safesante-matlc-clock',
  templateUrl: './clock.component.html',
  styleUrls: ['./clock.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ClockComponent implements OnInit {
  constructor() {}

  ngOnInit() {
    console.log('////////INIT////////');
  }

  ngAfterViewInit() {
    this.startClock();
  }
  startClock() {
    var clock;
    $(document).ready(function () {
      console.log('flipping clock');
      clock = $('.clock').FlipClock({
        clockFace: 'MinuteCounter',
      });
    });
  }
}
