<div class="row">
    <div class="col">
        <div class="float-right p-2 pointer">
            <span class="close pointer" data-dismiss="modal" aria-label="Close" (click)="closeModal()">
                <span aria-hidden="true"><i class="fas fa-times-circle pointer"></i></span>
            </span>
        </div>
    </div>
</div>
<div class="row m-2 text-center">
    <img class="mx-auto" style="height: 380px;" src="../../../assets/objet_connecte_safesante.png">
</div>
<div class="row m-3 m-3 p-3">

    <div class="col-sm-12">
        <h3>Nous n'arrivons pas à détecter la box santé. Celle-ci est indispensable à votre bonne prise en charge par le
            médecin</h3>
    </div>
</div>

<div class="row m-3" style="text-align:center">
    <!--div class="col-4" style="text-align:center">
        <button *ngIf="devicePairingStatus!='PAIRED'" type="submit border-r-5"
            class="mx-auto color-white p-2 mx-auto shadow purplebackground cp font16 bold text-uppercase border-radius5"
            (click)="boxNotConnected()">passer</button>
    </!--div-->
    <div class="col-6" style="text-align:center">
        <button type="submit border-r-5"
            class="mx-auto color-white p-2 mx-auto shadow redbackground cp font16 bold text-uppercase border-radius5"
            (click)="connectTheBox()">Reconnecter la box</button>
    </div>
    <div class="col-6" style="text-align:center">
        <button type="submit border-r-5"
            class="mx-auto color-white p-2  mx-auto shadow greenbackground cp font16 bold text-uppercase border-radius5"
            (click)="boxConnected()">J'ai connecté la box</button>
    </div>
</div>
