<div class="w-100 d-flex align-items-center">
  <mat-form-field class="time-entity">
    <mat-select
      [(ngModel)]="selectedHours"
      placeholder="Hr"
      (ngModelChange)="changeValue()"
      [disabled]="disabled || !selectHour"
    >
      <mat-option *ngFor="let option of hours" [value]="option">
        {{ option }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <span class="mx-2">:</span>
  <mat-form-field class="time-entity">
    <mat-select
      [(ngModel)]="selectedMinutes"
      placeholder="Min"
      (ngModelChange)="changeValue()"
      [disabled]="disabled"
    >
      <mat-option *ngFor="let option of minutes" [value]="option">
        {{ option }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
