<mat-form-field [floatLabel]="floatLabel">
  <input
    type="hidden"
    [matDatepicker]="picker"
    (dateChange)="dateChange($event)"
    [min]="minDate"
    [max]="maxDate"
  />
  <input
    matInput
    class="custom-datepicker-input"
    readonly
    [placeholder]="placeholder"
    [disabled]="disabled"
    [required]="false"
    [ngModel]="format(date)"
    (click)="picker.open()"
  />
  <mat-datepicker-toggle
    matSuffix
    [for]="picker"
    [disabled]="readonly"
  ></mat-datepicker-toggle>
  <mat-datepicker
    #picker
    [startView]="startView"
    [startAt]="startDate"
    [disabled]="readonly || disabled"
    (opened)="opened.emit()"
  >
  </mat-datepicker>
</mat-form-field>
