import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'safesante-matlc-prescription',
  templateUrl: './prescription.component.html',
  styleUrls: ['./prescription.component.scss'],
})
export class PrescriptionComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
