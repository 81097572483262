import { PocService } from './../poc/poc.service';
import { Component, OnInit } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';

import { NotificationsService } from 'angular2-notifications';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PasswordResetModel } from '../model/safesante-ysera';
import { NotificationConstants } from '../notifications.constant';

@Component({
  selector: 'safesante-matlc-reset-user-password',
  templateUrl: './reset-user-password.component.html',
  styleUrls: ['./reset-user-password.component.scss'],
})
export class ResetUserPasswordComponent implements OnInit {
  error = false;

  email: string;

  password: string;

  password2: string;

  isFormSubmittedOnce = false;

  areSamePasswords = true;

  respectPasswordFormat = true;

  private resetToken: string;

  constructor(
    private pocService: PocService,
    private activatedRoute: ActivatedRoute,
    public ngbModal: NgbModal,
    private router: Router,
    private notificationsService: NotificationsService
  ) {}

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.resetToken = params['resetToken'];
      console.log('got resetToken = ' + this.resetToken);

      this.pocService
        .getEmailForPasswordReset(this.resetToken)
        .subscribe((email: string) => {
          this.email = email;
        });
    });
  }

  validate() {
    this.isFormSubmittedOnce = true;
    const passwordResetModel = new PasswordResetModel();
    passwordResetModel.password = this.password;
    passwordResetModel.resetToken = this.resetToken;
    if (this.formIsValid()) {
      this.pocService.postNewPassword(passwordResetModel).subscribe(
        () => {
          this.notificationsService.success(
            NotificationConstants.PASSWORD_CHANGED_MESSAGE_SUCCESS,
            '',
            NotificationConstants.PROPERTIES
          );
          setTimeout(() => {
            this.router.navigate(['/accueil']);
          }, 3000);
        },
        () => {
          this.notificationsService.error(
            NotificationConstants.MESSAGE_ERROR,
            '',
            NotificationConstants.PROPERTIES
          );
        }
      );
    }
  }

  checkPasswords(): boolean {
    if (!this.isFormSubmittedOnce) return true;
    this.areSamePasswords =
      this.password == this.password2 &&
      this.password != null &&
      this.password2 != null &&
      this.password != '' &&
      this.password2 != '';
    return this.areSamePasswords;
  }

  passwordRespectsFormat(): boolean {
    let respectPasswordFormat =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z!@#$&()\\-`.+,/\"\]\[*$\d]{8,}$/.test(
        this.password
      );
    if (this.isFormSubmittedOnce) {
      this.respectPasswordFormat = respectPasswordFormat;
    }
    return this.respectPasswordFormat;
  }

  formIsValid(): boolean {
    let isFormValid: boolean =
      this.passwordRespectsFormat() && this.checkPasswords();
    return isFormValid;
  }
}
