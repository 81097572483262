import { DatePipe } from '@angular/common';
import {
  Component,
  forwardRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'safesante-custom-time-picker',
  templateUrl: './custom-time-picker.component.html',
  styleUrls: ['./custom-time-picker.component.scss'],
  providers: [
    DatePipe,
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CustomTimePickerComponent),
      multi: true,
    },
  ],
})
export class CustomTimePickerComponent
  implements OnInit, OnChanges, ControlValueAccessor
{
  private onChange: (p: string) => void;
  private onTouch: (p: string) => void;
  disabled: boolean;
  data: string;
  hours: string[];
  minutes: string[];
  selectedHours: string;
  selectedMinutes: string;

  @Input() minuteStep = 1;
  @Input() minutesSelect;
  @Input() selectHour = true;
  @Input() maxMinutes = 59;
  @Input() minMinutes = 0;

  set value(val: string) {
    const split = val?.split(':');

    if (split?.length) {
      this.selectedHours = split[0];
      this.selectedMinutes = split[1];
    }

    if (this.selectedHours === '24') {
      this.selectedHours = '00';
    }

    if (this.onChange) {
      this.onChange(val);
    }

    if (this.onTouch) {
      this.onTouch(val);
    }
  }

  constructor() {}

  ngOnInit() {
    this.setHours();
    this.setMinutes();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.minuteStep) {
      this.setMinutes();
    }
  }

  registerOnChange(fn: (p: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: (p: string) => void): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(val: string): void {
    this.value = val;
  }

  private setHours() {
    this.hours = [
      '00',
      '01',
      '02',
      '03',
      '04',
      '05',
      '06',
      '07',
      '08',
      '09',
      '10',
      '11',
      '12',
      '13',
      '14',
      '15',
      '16',
      '17',
      '18',
      '19',
      '20',
      '21',
      '22',
      '23',
    ];
  }

  private setMinutes() {
    this.minutes = this.minutesSelect ? [...this.minutesSelect] : [];

    if (!this.minutesSelect) {
      for (
        let i = this.minMinutes;
        i <= this.maxMinutes;
        i += this.minuteStep
      ) {
        this.minutes.push(this.addLeadingZero(i));
      }
    }
  }

  private addLeadingZero(dateElement: number): string {
    return dateElement < 10
      ? '0' + dateElement.toString()
      : dateElement.toString();
  }

  changeValue() {
    this.writeValue(`${this.selectedHours || 0}:${this.selectedMinutes || 0}`);
  }
}
