import {
  Component,
  OnInit,
  Input,
  AfterContentInit,
  HostListener,
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { DeviceDetectorService } from 'ngx-device-detector';

declare function webViewerPrint();

@Component({
  selector: 'safesante-matlc-file-preview',
  templateUrl: './file-preview.component.html',
  styleUrls: ['./file-preview.component.scss'],
  host: {
    class: 'safesante-matlc-file-preview',
  },
})
export class FilePreviewComponent implements OnInit, AfterContentInit {
  @Input() file: any;
  /*   @Input() buttonTitle: string = null;
    @Input() close: string = null; */
  url: SafeResourceUrl;
  @Input() contentType: string;

  @Input() title: string;

  isMobile = false;

  pdfType = 'application/pdf';
  pdfSource: ArrayBuffer | SharedArrayBuffer | Blob | SafeResourceUrl;

  constructor(
    public activeModal: NgbActiveModal,
    public sanitizer: DomSanitizer,
    private deviceService: DeviceDetectorService
  ) {}

  ngAfterContentInit() {
    if (this.file) {
      this.url = this.sanitizer.bypassSecurityTrustResourceUrl(
        URL.createObjectURL(this.file)
      );
      if (this.contentType.includes(this.pdfType)) {
        this.pdfSource = this.file;
      }
    }
  }

  ngOnInit() {
    this.isMobile = this.deviceService.isMobile();
  }

  closeModal() {
    this.activeModal.close();
  }

  printDocument() {
    let input: HTMLInputElement = <HTMLInputElement>(
      document.getElementById('print')
    );
    if (input) {
      input.click();
    }
  }

  download() {
    var a = document.createElement('a');

    a.setAttribute('href', URL.createObjectURL(this.file));
    a.setAttribute('target', '_blank');
    let fileName;
    if (this.title) {
      fileName = this.title.replace(/['"]+/g, '');
    } else {
      if (this.contentType == this.pdfType) {
        fileName = 'compte-rendu';
      } else {
        fileName = 'document';
      }
    }

    a.setAttribute('download', fileName);
    // start download
    a.click();
  }

  _base64ToArrayBuffer(base64) {
    const binary_string = window.atob(base64);
    const len = binary_string.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
  }

  @HostListener('window:resize', ['$event']) onResize(event: any) {
    this.isMobile = this.deviceService.isMobile();
  }
}
