<div style="text-align:center;"
  class="h-100 w-100 vcenter app-patient">

  <img width="300" class="mx-auto" alt="Safesante Logo" src="../assets/cs-logo.png">
  <div class="form-content">
  <div class="row  content-box patient-cols">
    <div *ngIf="step == 0" class="col-6 step-one">
      <mat-form-field floatLabel="auto">
        <input
          (focus)="onFocus()"
          (blur)="onBlur()"
          [(ngModel)]="visitForm.customer.firstname"
          #input0
          matInput
          autofocus
          autocomplete="off"
          class="auto-complete-off"
          placeholder="Prénom"
          required
        />
      </mat-form-field>
    </div>
    <div *ngIf="step == 1" class="col-6">
      <mat-form-field floatLabel="auto"> 
        <input (focus)="onFocus()" (blur)="onBlur()" [(ngModel)]="visitForm.customer.lastname" #input1 matInput
          autofocus autocomplete="off" class="auto-complete-off"
          placeholder="Nom" required>
      </mat-form-field>
    </div>
    <div *ngIf="step == 2" class="col-6 f-20">
      <label>Sexe*: </label>
      <br />
      <mat-radio-group [(ngModel)]="visitForm.customer.gender">
        <mat-radio-button value="M">M</mat-radio-button>
        <mat-radio-button value="F">F</mat-radio-button>
      </mat-radio-group>
    </div>
    <div *ngIf="step == 3" class="col-6 date-picker">
      <mat-form-field id="width">
        <mat-label>Date de naissance</mat-label>

        <input
          (focus)="onFocus()"
          (blur)="onBlur()"
          #input3
          matInput
          [matDatepicker]="picker"
          [(ngModel)]="visitForm.customer.birthdate"
          (focus)="picker.open()"
          autocomplete="off"
          class="auto-complete-off"
          placeholder="Date de naissance"
        />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker
          #picker
          touchUi="true"
          yPosition="below"
          startView="multi-year"
          [startAt]="startDate"
        ></mat-datepicker>
      </mat-form-field>
    </div>

    <div *ngIf="step == 4" [ngClass]="{'col-6 ': !(isMobile || isPortrait), 'col-12 ': (isMobile || isPortrait),  'secuity-option ': !(isMobile || isPortrait) } ">
      <div class="row  ">
        <label>Veuillez choisir parmi les possibilités suivantes l'identifiant dont vous êtes porteur: * </label>
        <mat-radio-group fxLayout="column" [(ngModel)]="visitForm.customer.insuranceCase">
          <mat-radio-button value="SS">N° Sécurité sociale</mat-radio-button>
          <mat-radio-button value="AME">N° AME</mat-radio-button>
          <mat-radio-button value="NONE"
            >Payer votre consultation (25 €)</mat-radio-button
          >
        </mat-radio-group>
      </div>
    </div>
    <div
      *ngIf="step == 5 && visitForm.customer.insuranceCase == 'SS'"
      [ngClass]="{
        'col-6': !(isMobile || isPortrait),
        'col-12': isMobile || isPortrait
      }"
    >
      <mat-form-field id="width" floatLabel="auto">
        <input
          #input5
          (focus)="onFocus()"
          (blur)="onBlur()"
          [(ngModel)]="visitForm.customer.securiteSocialeNumber"
          matInput
          autofocus
          autocomplete="off"
          class="auto-complete-off"
          placeholder="N° Sécurité sociale"
          required
          style="margin-right: 500px"
        />
      </mat-form-field>
      <div
        *ngIf="step == 5"
        [ngClass]="{
          'col-6': !(isMobile || isPortrait),
          'col-12': isMobile || isPortrait
        }"
      >
        <p [ngClass]="{ 'text-left': true, 'text-green': true }">
          Votre numéro de sécurité sociale est composé de 13 chiffres, suivi
          d'une clé de contrôle de 2 chiffres
        </p>
        <p></p>
        <p
          *ngIf="!vitaleCardValid()"
          [ngClass]="{
            'text-left': true,
            'text-green': vitaleCardValid(),
            'text-red': !vitaleCardValid()
          }"
        >
          Votre numéro de sécurité sociale ne correspond pas au format attendu
        </p>
      </div>
    </div>
    <div
      *ngIf="step == 5 && visitForm.customer.insuranceCase == 'AME'"
      [ngClass]="{
        'col-6': !(isMobile || isPortrait),
        'col-12': isMobile || isPortrait
      }"
    >
      <mat-form-field id="width" floatLabel="auto">
        <input
          #input5
          (focus)="onFocus()"
          (blur)="onBlur()"
          [(ngModel)]="visitForm.customer.ameNumber"
          matInput
          autofocus
          autocomplete="off"
          class="auto-complete-off"
          placeholder="N° AME"
          required
          style="margin-right: 500px"
        />
      </mat-form-field>
      <div
        *ngIf="step == 5"
        [ngClass]="{
          'col-6': !(isMobile || isPortrait),
          'col-12': isMobile || isPortrait
        }"
      >
        <p [ngClass]="{ 'text-left': true, 'text-green': true }">
          Votre numéro AME est composé de 13 chiffres, suivi d'une clé de
          contrôle de 2 chiffres
        </p>
        <p></p>
        <p
          *ngIf="!ameNumberValid()"
          [ngClass]="{
            'text-left': true,
            'text-green': ameNumberValid(),
            'text-red': !ameNumberValid()
          }"
        >
          Votre numéro AME ne correspond pas au format attendu
        </p>
      </div>
    </div>

    <div *ngIf="step == 6" class="col-6 cases-box f-20">
      <label>Êtes-vous concerné par un de ces trois cas ? </label>
      <br />
      <mat-radio-group [(ngModel)]="visitForm.appointmentCase">
        <mat-radio-button value="CMU">CMU</mat-radio-button>
        <mat-radio-button value="ALD">ALD</mat-radio-button>
        <mat-radio-button value="MAT">Maternité</mat-radio-button>
      </mat-radio-group>
    </div>


    <div *ngIf="step == 7" class="col-6 f-20 cases-box user-mail-form">
      <label>Votre adresse email. Celle-ci servira à vous envoyer un mail renvoyant vers vos documents </label>
      <br>
      <mat-form-field floatLabel="auto">
        <input
          (focus)="onFocus()"
          (blur)="onBlur()"
          [(ngModel)]="visitForm.customer.email"
          #input1
          matInput
          autofocus
          autocomplete="off"
          class="auto-complete-off"
          placeholder="Email"
          required
        />
      </mat-form-field>
    </div>

    <div *ngIf="step == 8" class="col-7 start-btn">

      <button class="green-button" (click)="saveCustomerAndStartTLC()">Démarrer la consultation</button>

      <div class="row text-center" *ngIf="submitDisabled">
        <div class="mx-auto">
          <div class="col">
            <div class="spinner-border text-primary" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row h-100 page-icon">
    <div class="col-6">
      <div *ngIf="step > 0 " (click)="previousStep()" class="row justify-content-center previuos-arrw">

        <mat-icon role="button"  class="color_blue icon-scale-3 pointer prev-arrow"
          style="cursor:hand;pointer-events:initial;cursor: pointer;">arrow_back</mat-icon>
        <h5 class="prev-arrow-text">Précédent</h5>
      </div>
      <div class="row justify-content-center"></div>
    </div>
    <div class="col-6 ">
      <div *ngIf="step < maxStep && isStepValid()" (click)="nextStep()" class="row justify-content-center next-button">
        <h5 class="next-arrow-text">Suivant</h5>
        <mat-icon role="button"  class="color_blue icon-scale-3 ml-4 mr-4 pointer next-arrow"
          style="cursor:hand;pointer-events:initial;cursor: pointer; ">arrow_forward</mat-icon>
      </div>
    </div>
  </div>
  </div>
  <div class="row justify-content-center annuler-btn">
    <button class="color-light-blue text-center background-transparent border-0 font13 cancel-btn"
      (click)="return()">Annuler</button>
  </div>
</div>
