import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { DatePipe } from '@angular/common';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { DeviceDetectorService } from 'ngx-device-detector';
import { FloatLabelType } from '@angular/material/form-field';

// todo: implement ng_value_accessor
@Component({
  selector: 'safesante-custom-date-picker',
  templateUrl: './custom-date-picker.component.html',
  styleUrls: ['./custom-date-picker.component.scss'],
  providers: [
    DatePipe,
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CustomDatePickerComponent),
      multi: true,
    },
  ],
})
export class CustomDatePickerComponent
  implements OnInit, ControlValueAccessor, Validator
{
  private onChange: (p: string) => void;
  private onTouch: (p: string) => void;
  date: Date;
  inputShouldBeEmpty = true;
  disabled: boolean;
  startDate: Date;
  isMobile = false;
  min: Date;
  max: Date;
  start: Date;

  @Input() minDate: Date;
  @Input() readonly: boolean;
  @Input() maxDate: Date;
  @Input() outputDateFormat = 'yyyy-MM-dd';
  @Input() placeholder: string;
  @Input() isBirthdayDate = false;
  @Input() startView: 'month' | 'year' | 'multi-year' = 'month';
  @Input() floatLabel: FloatLabelType = 'never';
  @Output() opened = new EventEmitter<void>();

  set value(val: string) {
    this.date = new Date(val);

    const offset = this.date.getTimezoneOffset();

    this.date.setMinutes(offset);
    this.inputShouldBeEmpty = !val;

    if (this.onChange) {
      this.onChange(val);
    }

    if (this.onTouch) {
      this.onTouch(val);
    }
  }

  constructor(
    private deviceService: DeviceDetectorService,
    private datepipe: DatePipe
  ) {}

  ngOnInit() {
    if (this.isBirthdayDate) {
      const now = new Date();

      this.startView = 'multi-year';
      this.startDate = new Date(
        now.getFullYear() - 40,
        now.getMonth(),
        now.getDate()
      );
      this.minDate = new Date(
        now.getFullYear() - 120,
        now.getMonth(),
        now.getDate()
      );
      this.maxDate = now;
    }
  }

  registerOnChange(fn: (p: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: (p: string) => void): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(val: string): void {
    this.value = val;
  }

  dateChange($event: MatDatepickerInputEvent<Date>) {
    this.writeValue(
      this.datepipe.transform($event.value, this.outputDateFormat)
    );
  }

  format(date: Date): string {
    if (date && !isNaN(date.getTime()) && !this.inputShouldBeEmpty) {
      return this.datepipe.transform(date, 'dd-MM-yyyy');
    }

    return '';
  }

  validate(control: AbstractControl): ValidationErrors {
    if (this.date && isNaN(this.date.getTime())) {
      return {
        customDatepicker: 'Invalid Date',
      };
    }

    return null;
  }
}
