import { PairingDeviceComponent } from './pairing-device/pairing-device.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login/login.component';
import { RegisterPocComponent } from './register-poc/register-poc.component';



const routes: Routes = [
  // { path: 'pharmacie', component: PairingDeviceComponent },
  // { path: 'login', component: LoginComponent },
  // { path: 'registerPoc', component: RegisterPocComponent}

];

@NgModule({
  imports: [
    RouterModule,
    RouterModule.forChild(routes),
    CommonModule],
    exports: [RouterModule]
})

export class PocRoutingModule { }
