import { Injectable } from '@angular/core';
import { Observable, Observer, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class WebsoketService {
  static connect(url: string): Subject<string> {
    const ws = new WebSocket(url);

    const observable = new Observable((observer) => {
      ws.onopen = () => {
        console.log(
          '%cWebSocket service :::: socket connected:',
          'background: green; color: white',
          url
        );
      };

      ws.onmessage = (event) => {
        observer.next(event.data);
        console.log(
          '%cWebSocket service :::: socket messaged:',
          'background: black; color: white',
          url
        );
      };

      ws.onerror = (event) => {
        observer.error(event);
        console.log(
          '%cWebSocket service :::: socket error:',
          'background: orange; color: white',
          url
        );
      };

      ws.onclose = () => {
        observer.complete();
        console.log(
          '%cWebSocket service :::: socket closed:',
          'background: maroon; color: white',
          url
        );
      };
    });

    const observer: Observer<string> = {
      next: (data: string) => {
        if (ws.readyState === WebSocket.OPEN) {
          ws.send(data);
        } else {
          setTimeout(() => {
            ws.send(data);
          }, 500);

          console.error('Cannot sent message: socket is closed');
        }
      },
      error: (error) => {console.error(error)},
      complete: () => {
        if (
          ws.readyState === WebSocket.CONNECTING ||
          ws.readyState === WebSocket.OPEN
        ) {
          ws.close();
        }
      },
    };

    return Subject.create(observer, observable);
  }
}
