<div class="background1  pl-3 pt-3 pr-3 pb-0 float-left w-100 pos-rel h-100">
    <div class="container-fluid">
        <header class="pt-4 pl-4 pr-4">
            <a class="navbar-brand" routerLink="/accueil"><img style="width: auto; height: 80px" src="../../assets/logo-safesante-blanc.png" alt="logo safesante blanc" /></a>
            <a class="nav-link color-white font16 bold float-right" href="https://www.safesante.fr/search">Découvrez MaTeleconsult</a>
        </header>
    </div>
    <div class="content-wrap">
        <div class="container mobilepad0">
            <div class="col-sm-12 w-100 mt-7r text-center mobilepad0">
                <div class="mainheading mb-5 color-white">
                    Réinitialiser votre mot de passe
                </div>
                <div class="col-sm-10 col-lg-8 d-inline-block mobilepad0">
                    <form class="backgroundwhite pt-5 pl-5 pr-5 pb-2 shadow mp10 mb-4">
                        <div class="w-100 form-container">
                            <form (submit)="resetPassword()">
                                <div class="row mb-3">
                                    <div class="col-sm-12">
                                        <div class="form-group sc">
                                            <label>Votre adresse email</label>

                                            <input type="email" class="font18" id="usr-email" [(ngModel)]="email" name="email" [ngClass]="{'form-control font18':true, 'invalid-input': !emailValid()}" required>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mb-3">
                                    <div class="col-sm-12">
                                        <div class="form-group sc">
                                            <button type="submit" class="green-btn w-100 color-white shadow cp font16 bold text-uppercase">Réinitialiser</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    <!-- <img src="img/teleconsultation-gynecologue.png" class="blue" alt="teleconsultation gynecologue"/>
    <img src="img/teleconsultation-remboursee-cnam.png" class="light-blue" alt="teleconsultation remboursee cnam" />
    <img src="img/safesante-agenda-medical-en-ligne.png" class="green" alt="safesante agenda medical en ligne" /> -->
</div>