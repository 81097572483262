import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PocService } from './../poc/poc.service';

@Component({
  selector: 'safesante-matlc-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  deviceStateEnum: any;
  deviceState: string = 'PAIRED';

  constructor(private router: Router, private pocService: PocService) {}

  ngOnInit() {}

  startTeleconsultation() {
    this.router.navigate(['/patient']);
  }

  goToLogin() {
    this.router.navigate(['/login']);
  }
}
